import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useEmployee from "../../../../hooks/useEmployee";
import useTenant from "../../../../hooks/useTenant";
import useBranch from "../../../../hooks/useBranch";

import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import FilterReset from "../../../../components/FilterReset";
import { searchPayload, convertedValue } from "../../../../utils/common";
import { OperatorConstants } from "../../../../constants/operator.constants";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomTextField from "../../../../components/CustomTextField";
import { useDialog } from "../../../../contexts/DialogContext";
import CustomDatePicker from "../../../../components/CustomDatePicker";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const statusOptions = [
  {
    label: "Active",
    value: "A",
  },
  {
    label: "InActive",
    value: "INA",
  },
];

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    userNameOperator: "eq",
    firstNameOperator: "eq",
    lastNameOperator: "eq",
    emailOperator: "eq",
    statusOperator: "eq",
    mobileOperator: "eq",
    expirationOperator: "btn",
    firstName: "",
    expiration: [],
    expirationTemp: "",
    mobileNo: "",
    emailId: "",
    userName: "",
    lastName: "",
    status: [],
  };

  const { fields, payload, setPayload, setFilterResetFunc } = props;

  const [statusMultiValue, setStatusMultiValue] = useState(false);

  const [defaultStatus, setDefaultStatus] = useState([]);

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    setFilterResetFunc(() => reset);
  }, [reset]);

  const statusOpValue = watch("statusOperator");

  const onSubmit = async (data) => {
    try {
      let filters = [];
      console.log(data, "data33333333333333");

      if (data.firstName != null && Object.keys(data.firstName).length != 0) {
        filters.push({
          fieldName: "firstName",
          fieldValue: data.firstName,
          op: data.firstNameOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.lastName != null && Object.keys(data.lastName).length != 0) {
        filters.push({
          fieldName: "lastName",
          fieldValue: data.lastName,
          op: data.lastNameOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.emailId != null && Object.keys(data.emailId).length != 0) {
        filters.push({
          fieldName: "emailId",
          fieldValue: data.emailId,
          op: data.emailOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.mobileNo != null && Object.keys(data.mobileNo).length != 0) {
        filters.push({
          fieldName: "mobileNo",
          fieldValue: data.mobileNo,
          op: data.mobileOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.userName != null && Object.keys(data.userName).length != 0) {
        filters.push({
          fieldName: "userName",
          fieldValue: data.userName,
          op: data.userNameOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }

      if (data.status != null && Object.keys(data.status).length != 0) {
        filters.push({
          fieldName: "status",
          fieldValue: convertedValue(data.status, "value").join(","),
          op: data.statusOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.expiration != null && Object.keys(data.expiration).length != 0) {
        filters.push({
          fieldName: "expiration",
          fieldValue: data.expirationTemp,
          op: data.expirationOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.log(err, "errerrerrerr");
    }
  };

  useEffect(() => {
    if (statusOpValue == "in" || statusOpValue == "nin") {
      setDefaultStatus([]);
      setValue("status", []);
      setStatusMultiValue(true);
    } else {
      setDefaultStatus({});
      setValue("status", {});
      setStatusMultiValue(false);
    }
  }, [statusOpValue]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="userNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("user_name")}
                name="userName"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="firstNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("first_name")}
                name="firstName"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="lastNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("last_name")}
                name="lastName"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="emailOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("email_id")}
                name="emailId"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="mobileOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("mobile_number")}
                name="mobileNo"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item xs={4} sm={6} md={2}>
              {" "}
              <ReactHookFormSelect
                name="expirationOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.date).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={8} sm={6} md={4}>
              <CustomDatePicker
                name="expiration"
                nameTemp="expirationTemp"
                label="expiration"
                range={true}
                control={control}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="statusOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <SearchableAutoComplete
                control={control}
                data={statusOptions}
                label="Status"
                payload={null}
                setPayload={null}
                loading={false}
                name="status"
                multiple={statusMultiValue}
                defaultValue={defaultStatus}
              />
            </Grid>
          </Grid>

          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
