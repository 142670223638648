import * as React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { useDialog } from "../contexts/DialogContext";

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
  Button,
} from "@mui/material";
import AssignUser from "../pages/lead/components/assign";
import ShowAppPassword from "../pages/users/userlist/components/ShowApp";
import Terms from "./Terms";
import ContactUs from "./ContactUs";
import CancellationRefundPolicy from "./CancelationAndRefund";
import PrivacyPolicy from "./PrivacyPolicy";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)} ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  const theme = useTheme();
  const { openDialog } = useDialog();

  const termClick = (id) => {
    return openDialog("terms_and_condition", "Terms & Conditions", <Terms />, "md");
  };

  const contactClick = (id) => {
    return openDialog("contact", "Contact Us", <ContactUs />, "md");
  };
  const cancellationAndRefundClick = (id) => {
    return openDialog(
      "cancalleation_refund_policy",
      "Cancellation & Refund Policy",
      <CancellationRefundPolicy />,
      "md",
    );
  };

  const privacyAndPolicyClick = (id) => {
    return openDialog("privacy_and_policy", "Privacy and Policy", <PrivacyPolicy />, "md");
  };

  return (
    <Wrapper theme={theme}>
      <Grid container spacing={4} alignItems="center">
        {/* Left Section (Buttons in a Row) */}
        <Grid item container xs={12} md>
          <Grid container spacing={2} direction="row">
            <List>
              <ListItemButton component="span" href="#" theme={theme} onClick={() => termClick()}>
                <ListItemText primary="Terms and Conditions" theme={theme} />
              </ListItemButton>
              <ListItemButton
                component="span"
                href="#"
                theme={theme}
                onClick={() => contactClick()}
              >
                <ListItemText primary="Contact Us" theme={theme} />
              </ListItemButton>
              <ListItemButton
                component="span"
                href="#"
                theme={theme}
                onClick={() => cancellationAndRefundClick()}
              >
                <ListItemText primary="Cancellation & Refund Policy" theme={theme} />
              </ListItemButton>
              <ListItemButton
                component="span"
                href="#"
                theme={theme}
                onClick={() => privacyAndPolicyClick()}
              >
                <ListItemText primary="Privacy Policy" theme={theme} />
              </ListItemButton>
            </List>
            {/* <Grid item>
              <Button variant="contained" onClick={() => termClick()}>
                Terms and Conditions
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => contactClick()}>
                Contact Us
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => showAppClick()}>
                Cancellation and Refund Policy
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => showAppClick()}>
                Privacy Policy
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => showAppClick()}>
                Shipping and Delivery Policy
              </Button>
            </Grid> */}
          </Grid>
        </Grid>

        {/* Footer Section */}
        <Grid item xs={12} md="auto">
          <List>
            <ListItemButton theme={theme}>
              <ListItemText primary={`© ${new Date().getFullYear()}`} theme={theme} />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
