import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { useFormContext } from "react-hook-form";

import { Grid } from "@mui/material";

import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomCheckBox from "../../../../components/CustomCheckBox";
import useNasGroup from "../../../../hooks/useNasGroup";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";

const currentNasGroupPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const PlanDetail = (props) => {
  const { autoSearchNasGroup, nasGroupAutoSearchResults } = useNasGroup();
  const { t } = useTranslation();
  const { validationFields } = props;
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext({
    mode: "onChange",
  });

  const [nasGroupPayload, setNasGroupPayload] = useState(currentNasGroupPayload);
  const [nasGroupOptions, setNasGroupOptions] = useState([]);

  const fetchNasGroups = async () => {
    await autoSearchNasGroup(nasGroupPayload);
  };

  useEffect(() => {
    fetchNasGroups();
  }, [nasGroupPayload]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (nasGroupAutoSearchResults.data.length !== 0) {
      const results = [...nasGroupOptions, ...nasGroupAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setNasGroupOptions(filteredResults);
    }
  }, [nasGroupAutoSearchResults]);

  const planDataType = watch("planDataType");

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <CustomTextField
          label={t("plan_name")}
          name="planName"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <ReactHookFormSelect
          name="planType"
          label={t("plan_type")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"Prepaid"}>Prepaid</MenuItem>
          <MenuItem value={"Card"}>Card</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <CustomTextField
          label={t("download_speed")}
          name="downloadSpeed"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <CustomTextField
          label={t("upload_speed")}
          name="uploadSpeed"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <ReactHookFormSelect
          name="planDataType"
          label={t("plan_data_type")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"Unlimited"}>Unlimited</MenuItem>
          <MenuItem value={"Unlimited_FUP"}>Unlimited/FUP</MenuItem>
          <MenuItem value={"Limited"}>Limited</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      {(planDataType == "Limited" || planDataType == "Unlimited_FUP") && (
        <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
          <CustomTextField
            label={t("total_limit")}
            name="totalLimit"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={validationFields}
          />
        </Grid>
      )}

      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <ReactHookFormSelect
          name="taxType"
          label={t("tax_type")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"INCLUDING_TAX"}>Including Tax</MenuItem>
          <MenuItem value={"EXCLUDING_TAX"}>Excluding Tax</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <ReactHookFormSelect
          name="serviceType"
          label={t("service_type")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"INTERNET"}>Internet</MenuItem>
          <MenuItem value={"COMBO"}>Combo</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <SearchableAutoComplete
          control={control}
          data={nasGroupOptions}
          label="Select Nas Group"
          payload={nasGroupPayload}
          setPayload={setNasGroupPayload}
          loading={false}
          name="nasGroup"
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={validationFields}
        />
      </Grid>

      <Grid item xs={9.5} sm={12} md={6} lg={6} xl={6}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};

export default PlanDetail;
