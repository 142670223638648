import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import useSessionHistory from "../../../hooks/useSessionHistory";
import useMainContentDimensions from "../../../utils/mainContentDimensions";
import moment from "moment";
import { convertSecondToDays, formatBytes } from "../../../utils/common";
import useUser from "../../../hooks/useUser";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function SessionHistory() {
  const { state } = useLocation();
  const { t } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();

  const { user, getUser } = useUser();

  const userId = searchParams.get("id");

  const currentPayload = {
    filters: [
      {
        fieldName: "userName",
        fieldValue: user.userName,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
        attributeName: "radUser",
        entityName: "RadUserEntity",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "id",
        sort: "DESC",
      },
    ],
  };

  useEffect(() => {
    getUser(userId);
  }, [userId]);

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getAllSessionHistory, sessionHistories, paging, downloadFile } = useSessionHistory();
  const { openDialog } = useDialog();
  const { width: mainContentWidth } = useMainContentDimensions();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllSessionHistory(payload);
  };

  const onPageChange = (newPaginationModel) => {
    console.log(newPaginationModel, "pagepage");
    const pagination = {
      pageNumber: newPaginationModel.page == 0 ? 1 : newPaginationModel.page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
    setPaginationModel(newPaginationModel);
  };

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  // const breadCrump = () => {
  //   return (
  //     <Breadcrumbs aria-label="Breadcrumb" mt={2}>
  //       <Typography>Invoices</Typography>
  //       <Typography>Paid</Typography>
  //     </Breadcrumbs>
  //   );
  // };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "userFullName",
      headerName: "Customer Name",
      width: 150,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 150,
    },
    {
      field: "macAddress",
      headerName: "Mac",
      width: 150,
    },
    {
      field: "userPlan",
      headerName: "User Service",
      width: 150,
    },
    {
      field: "runningPlan",
      headerName: "Running Service",
      width: 150,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      valueGetter: (params) => moment(params).format("DD-MM-YYYY HH:mm a"),
      width: 250,
    },
    {
      field: "endTime",
      headerName: "End Time",
      valueGetter: (params) => moment(params).format("DD-MM-YYYY HH:mm a"),
      width: 250,
    },
    {
      field: "onlineTime",
      headerName: "Online Time",
      valueGetter: (params) => convertSecondToDays(params),
      width: 200,
    },
    {
      field: "download",
      headerName: "Downloaded",
      valueGetter: (params) => formatBytes(params),

      width: 130,
    },
    {
      field: "upload",
      headerName: "Uploaded",
      valueGetter: (params) => formatBytes(params),
      width: 130,
    },
    {
      field: "total",
      headerName: "Total",
      valueGetter: (params) => formatBytes(params),
      width: 150,
    },
    {
      field: "ipAddress",
      headerName: "Ip Address",
      width: 150,
    },
    {
      field: "nasIp",
      headerName: "Nas Ip",
      width: 150,
    },
    {
      field: "acctTerminateCause",
      headerName: "Terminate cause",
      width: 150,
    },
  ];

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={<Toolbar downloadFile={downloadFile} refreshPage={refreshPage} />}
        title={t("session_history")}
        // subheader={breadCrump()}

        downloadFile={downloadFile}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={sessionHistories}
          getRowId={(row) => row["radAcctId"]}
          rowCount={paging.totalNumberOfRecords}
          loading={false}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
          }
          sx={{
            "& .MuiDataGrid-row.even-row": {
              backgroundColor: "#f9f9f9", // Light gray for even rows
            },
            "& .MuiDataGrid-row.odd-row": {
              backgroundColor: "#ffffff", // White for odd rows
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

export default SessionHistory;
