import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import Upload from "../components/Upload";
import { Card, CardHeader, Breadcrumbs as MuiBreadcrumbs, Paper as MuiPaper } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import useLead from "../../../hooks/useLead";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function LeadHistory() {
  const { state } = useLocation();

  const currentPayload = {
    filters: [
      {
        fieldName: "leadId",
        fieldValue: state?.id,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "leadHistoryId",
        sort: "DESC",
      },
    ],
  };
  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getAllLeadHistory, leadHistories, paging, downloadFile } = useLead();
  const { openDialog } = useDialog();

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllLeadHistory(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const openDownloadDialog = () => {
    return openDialog("upload_lead", "Upload lead", <Upload key={getTimeStamp()} />);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "createdOn",
      headerName: "Changed Date",
      width: 300,
    },
    {
      field: "employee",
      headerName: "Assign To",
      valueGetter: (params) => params?.employeeName,
      width: 300,
    },

    {
      field: "status",
      headerName: "Status",
      width: 300,
    },

    {
      field: "createdBy",
      headerName: "Status Changed By",
      type: "string",
      width: 300,
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 300,
    },
  ];
  return (
    <Card mb={6}>
      <CardHeader
        action={
          <Toolbar
            addPage={null}
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
          />
        }
      />
      <Paper>
        <div style={{ height: height - 180, width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={leadHistories}
            getRowId={(row) => row["leadHistoryId"]}
            rowCount={paging.totalNumberOfRecords}
            loading={false}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
            }
            sx={{
              "& .MuiDataGrid-row.even-row": {
                backgroundColor: "#f9f9f9", // Light gray for even rows
              },
              "& .MuiDataGrid-row.odd-row": {
                backgroundColor: "#ffffff", // White for odd rows
              },
            }}
          />
        </div>
      </Paper>
    </Card>
  );
}

export default LeadHistory;
