import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import usePlanHistory from "../../../hooks/usePlanHistory";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function PlanHistory(props) {
  const { id, data } = props;
  const { state } = useLocation();
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getAllUserPlanHistory, planHistories, paging, downloadFile } = usePlanHistory();
  const { openDialog } = useDialog();
  const userPlanHistoryId = searchParams.get("id");

  const currentPayload = {
    filters: [
      {
        fieldName: "radUserId",
        fieldValue: userPlanHistoryId,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
        attributeName: "radUser",
        entityName: "RadUserEntity",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "createdAt",
        sort: "DESC",
      },
    ],
  };

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getAllUserPlanHistory(payload);
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Invoices</Typography>
        <Typography>Paid</Typography>
      </Breadcrumbs>
    );
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "newPlanName",
      headerName: "New Plan",
      flex: 1,
    },
    {
      field: "oldPlanName",
      headerName: "Old Plan",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Changed On",
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: "Changed By",
      flex: 1,
    },
  ];

  return (
    <Card mb={6}>
      <CardHeader
        action={<Toolbar downloadFile={downloadFile} />}
        title={t("plan_history")}
        subheader=""
        downloadFile={downloadFile}
      />
      <Paper>
        <div style={{ height: height - 180, width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={planHistories}
            getRowId={(row) => row["userPlanHistoryId"]}
            rowCount={paging.totalNumberOfRecords}
            loading={false}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
            }
            sx={{
              "& .MuiDataGrid-row.even-row": {
                backgroundColor: "#f9f9f9", // Light gray for even rows
              },
              "& .MuiDataGrid-row.odd-row": {
                backgroundColor: "#ffffff", // White for odd rows
              },
            }}
          />
        </div>
      </Paper>
    </Card>
  );
}

export default PlanHistory;
