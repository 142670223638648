import React from "react";
import { ContextProviderComposer } from "../utils/ContextProvider";
import { DashboardProvider } from "../contexts/DashboardContext";
import { DialogProvider } from "../contexts/DialogContext";
import { TenantProvider } from "../contexts/TenantContext";
import { UserProvider } from "../contexts/UserContext";
import { AgreementTrackerProvider } from "../contexts/AgreementTrackerContext";
import { BalanceStatementProvider } from "../contexts/BalanceStatementContext";
import { DailyUserProvider } from "../contexts/DailyUserContext";
import { RadUserPlanProvider } from "../contexts/RadUserPlanContext";
import { RateProvider } from "../contexts/RateContext";
import { PlanProvider } from "../contexts/PlanContext";

export const DashboardComposer = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <DashboardProvider key={0} />,
        <TenantProvider key={1} />,
        <UserProvider key={2} />,
        <AgreementTrackerProvider key={3} />,
        <BalanceStatementProvider key={4} />,
        <DailyUserProvider key={5} />,
        <RadUserPlanProvider key={6} />,
        <RateProvider key={6} />,
        <PlanProvider key={7} />,
        <DialogProvider key={8} />,
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};
