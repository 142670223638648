export const UserConstants = {
  FETCH_USER_REQUEST: "FETCH_USER_REQUEST",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE: "FETCH_USER_FAILURE",
  ADD_USER_REQUEST: "ADD_USER_REQUEST",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",
  CREATE_ORDER_REQUEST: "CREATE_ORDER_REQUEST",
  CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
  CREATE_ORDER_FAILURE: "CREATE_ORDER_FAILURE",
  CANCEL_ORDER_REQUEST: "CANCEL_ORDER_REQUEST",
  CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
  CANCEL_ORDER_FAILURE: "CANCEL_ORDER_FAILURE",
  ADD_MATERIAL_REQUEST: "ADD_MATERIAL_REQUEST",
  ADD_MATERIAL_SUCCESS: "ADD_MATERIAL_SUCCESS",
  ADD_MATERIAL_FAILURE: "ADD_MATERIAL_FAILURE",
  ADD_DISCONNECT_USER_REQUEST: "ADD_DISCONNECT_USER_REQUEST",
  ADD_DISCONNECT_USER_SUCCESS: "ADD_DISCONNECT_USER_SUCCESS",
  ADD_DISCONNECT_USER_FAILURE: "ADD_DISCONNECT_USER_FAILURE",
  ADD_CLOSE_USER_SESSION_REQUEST: "ADD_CLOSE_USER_SESSION_REQUEST",
  ADD_CLOSE_USER_SESSION_SUCCESS: "ADD_CLOSE_USER_SESSION_SUCCESS",
  ADD_CLOSE_USER_SESSION_FAILURE: "ADD_CLOSE_USER_SESSION_FAILURE",
  UPDATE_MATERIAL_REQUEST: "UPDATE_MATERIAL_REQUEST",
  UPDATE_MATERIAL_SUCCESS: "UPDATE_MATERIAL_SUCCESS",
  UPDATE_MATERIAL_FAILURE: "UPDATE_MATERIAL_FAILURE",
  UPDATE_SUSPEND_USER_REQUEST: "UPDATE_SUSPEND_USER_REQUEST",
  UPDATE_SUSPEND_USER_SUCCESS: "UPDATE_SUSPEND_USER_SUCCESS",
  UPDATE_SUSPEND_USER_FAILURE: "UPDATE_SUSPEND_USER_FAILURE",
  ADD_AUT0_RENEWAL_REQUEST: "ADD_AUT0_RENEWAL_REQUEST",
  ADD_AUT0_RENEWAL_SUCCESS: "ADD_AUT0_RENEWAL_SUCCESS",
  ADD_AUT0_RENEWAL_FAILURE: "ADD_AUT0_RENEWAL_FAILURE",
  GET_MATERIAL_REQUEST: "GET_MATERIAL_REQUEST",
  GET_MATERIAL_SUCCESS: "GET_MATERIAL_SUCCESS",
  GET_MATERIAL_FAILURE: "GET_MATERIAL_FAILURE",
  GET_SHOW_ROUTER_PASSWORD_REQUEST: "GET_SHOW_ROUTER_PASSWORD_REQUEST",
  GET_SHOW_ROUTER_PASSWORD_SUCCESS: "GET_SHOW_ROUTER_PASSWORD_SUCCESS",
  GET_SHOW_ROUTER_PASSWORD_FAILURE: "GET_SHOW_ROUTER_PASSWORD_FAILURE",
  GET_SHOW_APP_PASSWORD_REQUEST: "GET_SHOW_APP_PASSWORD_REQUEST",
  GET_SHOW_APP_PASSWORD_SUCCESS: "GET_SHOW_APP_PASSWORD_SUCCESS",
  GET_SHOW_APP_PASSWORD_FAILURE: "GET_SHOW_APP_PASSWORD_FAILURE",
  FETCH_MATERIAL_REQUEST: "FETCH_MATERIAL_REQUEST",
  FETCH_MATERIAL_SUCCESS: "FETCH_MATERIAL_SUCCESS",
  FETCH_MATERIAL_FAILURE: "FETCH_MATERIAL_FAILURE",
  RENEW_USER_REQUEST: "RENEW_USER_REQUEST",
  RENEW_USER_SUCCESS: "RENEW_USER_SUCCESS",
  RENEW_USER_FAILURE: "RENEW_USER_FAILURE",
  CHANGE_VALIDITY_REQUEST: "CHANGE_VALIDITY_REQUEST",
  CHANGE_VALIDITY_SUCCESS: "CHANGE_VALIDITY_SUCCESS",
  CHANGE_VALIDITY_FAILURE: "CHANGE_VALIDITY_FAILURE",
  USER_SEARCH_REQUEST: "USER_SEARCH_REQUEST",
  USER_SEARCH_SUCCESS: "USER_SEARCH_SUCCESS",
  USER_SEARCH_FAILURE: "USER_SEARCH_FAILURE",
  USER_AUTO_SEARCH_REQUEST: "USER_AUTO_SEARCH_REQUEST",
  USER_AUTO_SEARCH_SUCCESS: "USER_AUTO_SEARCH_SUCCESS",
  USER_AUTO_SEARCH_FAILURE: "USER_AUTO_SEARCH_FAILURE",
  GLOBAL_AUTO_SEARCH_REQUEST: "GLOBAL_AUTO_SEARCH_REQUEST",
  GLOBAL_AUTO_SEARCH_SUCCESS: "GLOBAL_AUTO_SEARCH_SUCCESS",
  GLOBAL_AUTO_SEARCH_FAILURE: "GLOBAL_AUTO_SEARCH_FAILURE",
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  DOWNLOAD_USER_REQUEST: "DOWNLOAD_USER_REQUEST",
  DOWNLOAD_USER_SUCCESS: "DOWNLOAD_USER_SUCCESS",
  DOWNLOAD_USER_FAILURE: "DOWNLOAD_USER_FAILURE",
  UPLOAD_USER_REQUEST: "UPLOAD_USER_REQUEST",
  UPLOAD_USER_SUCCESS: "UPLOAD_USER_SUCCESS",
  UPLOAD_USER_FAILURE: "UPLOAD_USER_FAILURE",
  ONLINE_USER_REQUEST: "ONLINE_USER_REQUEST",
  ONLINE_USER_SUCCESS: "ONLINE_USER_SUCCESS",
  ONLINE_USER_FAILURE: "ONLINE_USER_FAILURE",
  GET_RENEWAL_REQUEST: "GET_RENEWAL_REQUEST",
  GET_RENEWAL_SUCCESS: "GET_RENEWAL_SUCCESS",
  GET_RENEWAL_FAILURE: "GET_RENEWAL_FAILURE",
  USER_DASHBOARD_COUNT_REQUEST: "USER_DASHBOARD_COUNT_REQUEST",
  USER_DASHBOARD_COUNT_SUCCESS: "USER_DASHBOARD_COUNT_SUCCESS",
  USER_DASHBOARD_COUNT_FAILURE: "USER_DASHBOARD_COUNT_FAILURE",
  USER_DETAIL_INVOICE_REQUEST: "USER_DETAIL_INVOICE_REQUEST",
  USER_DETAIL_INVOICE_SUCCESS: "USER_DETAIL_INVOICE_SUCCESS",
  USER_DETAIL_INVOICE_FAILURE: "USER_DETAIL_INVOICE_FAILURE",
  UPDATE_PWD_REQUEST: "UPDATE_PWD_REQUEST",
  UPDATE_PWD_SUCCESS: "UPDATE_PWD_SUCCESS",
  UPDATE_PWD_FAILURE: "UPDATE_PWD_FAILURE",
  UPDATE_PLAN_REQUEST: "UPDATE_PLAN_REQUEST",
  UPDATE_PLAN_SUCCESS: "UPDATE_PLAN_SUCCESS",
  UPDATE_PLAN_FAILURE: "UPDATE_PLAN_FAILURE",
  UPDATE_USER_NAME_REQUEST: "UPDATE_USER_NAME_REQUEST",
  UPDATE_USER_NAME_SUCCESS: "UPDATE_USER_NAME_SUCCESS",
  UPDATE_USER_NAME_FAILURE: "UPDATE_USER_NAME_FAILURE",
  UPDATE_ON_HOLD_REQUEST: "UPDATE_ON_HOLD_REQUEST",
  UPDATE_ON_HOLD_SUCCESS: "UPDATE_ON_HOLD_SUCCESS",
  UPDATE_ON_HOLD_FAILURE: "UPDATE_ON_HOLD_FAILURE",
  GET_NEXT_EXPIRATION_REQUEST: "GET_NEXT_EXPIRATION_REQUEST",
  GET_NEXT_EXPIRATION_SUCCESS: "GET_NEXT_EXPIRATION_SUCCESS",
  GET_NEXT_EXPIRATION_FAILURE: "GET_NEXT_EXPIRATION_FAILURE",
};
