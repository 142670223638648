import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDialog } from "../contexts/DialogContext";
import useUser from "../hooks/useUser";
import CustomTextField from "./CustomTextField";

import { showSuccessSnackbar, showErrorSnackbar } from "../redux/slices/snackbar";

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold", // Bold text
  color: "#000", // Black text
});

const Alert = styled(MuiAlert)(spacing);

const ContactUs = (props) => {
  const dispatch = useDispatch();
  const {
    getShowAppPassword,
    getShowPassword,
    showPass,
    showAppPass,
    isRenewSubmitting,
    updateAppPassword,
  } = useUser();
  const { radUserId } = props;

  const { t } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();

  const methods = useForm({
    resolver: yupResolver(),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;

  const { unSetDialog, openDialog } = useDialog();

  const fetchData = async () => {
    await getShowAppPassword(searchParams.get("id"));
  };

  console.log(searchParams.get("id"), "searrcchhh");

  useEffect(() => {
    if (searchParams && searchParams.get("id")) {
      fetchData();
    }
  }, [searchParams]);

  React.useEffect(() => {
    const initialValues = {
      showAppPassword: showAppPass,
    };
    reset(initialValues);
  }, [showAppPass]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Grid container spacing={12} className="row" pt={2}>
          <Grid item md={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "none", fontWeight: "bold" }}>
                      Last updated on Nov 27th 2024
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ paddingTop: "5px", borderBottom: "none" }}>
                      <Typography>You may contact us using the information below:</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ paddingTop: "5px", borderBottom: "none" }}>
                      <Typography>
                        Merchant Legal entity name: SUCCESS TELECOM PRIVATE LIMITED{" "}
                      </Typography>
                      <Typography>
                        Registered Address: No:01, Muppadai Nagar, Vellanoor, Avadi Tiruvallur TAMIL
                        NADU 600062
                      </Typography>{" "}
                      <Typography>
                        {" "}
                        Operational Address: No:01, Muppadai Nagar, Vellanoor, Avadi Tiruvallur
                        TAMIL NADU 600062
                      </Typography>{" "}
                      <Typography>Telephone No: 9840604241</Typography>{" "}
                      <Typography>E-Mail ID: successtele20@gmail.com</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ paddingTop: "5px", borderBottom: "none" }}>
                      <Typography>
                        <i>
                          Disclaimer: The above content is created at SUCCESS TELECOM PRIVATE
                          LIMITED's sole discretion. Razorpay shall not be liable for any content
                          provided here and shall not be responsible for any claims and liability
                          that may arise due to merchant’s non-adherence to it.
                        </i>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </FormProvider>
    </React.Fragment>
  );
};
export default ContactUs;
