import React, { useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "@emotion/styled";

import useUser from "../../../../hooks/useUser";

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold", // Bold text
  color: "#000", // Black text
});

const ShowRouterPass = (props) => {
  const { getShowPassword, showPass } = useUser();
  let [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const methods = useForm({
    resolver: yupResolver(),
  });
  const { reset } = methods;

  const fetchData = async () => {
    await getShowPassword(searchParams.get("id"));
  };

  useEffect(() => {
    if (searchParams && searchParams.get("id")) {
      fetchData();
    }
  }, [searchParams]);

  useEffect(() => {
    const initialValues = {
      showRouterPassword: showPass,
    };
    reset(initialValues);
  }, [showPass]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Grid container spacing={12} className="row" pt={2}>
          <Grid item md={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {t("Password")}
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        borderBottom: "none",
                      }}
                    >
                      {showPass
                        ? typeof showPass === "object"
                          ? JSON.stringify(showPass)
                          : showPass
                        : "N/A"}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </FormProvider>
    </React.Fragment>
  );
};

export default ShowRouterPass;
