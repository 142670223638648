import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import moment from "moment";
import EditDelete from "../../../components/EditDelete";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import useUser from "../../../hooks/useUser";
import AdvanceFilter from "../userlist/components/AdvanceFilter";
import useMainContentDimensions from "../../../utils/mainContentDimensions";
import { convertSecondToDays, formatBytes } from "../../../utils/common";
import DisconnectUser from "../user_details/DisconnectUser";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>User</Typography>
      <Typography>Online</Typography>
    </Breadcrumbs>
  );
};

const CustomDataGrid = (props) => {
  const { isLoadingDownload, downloadFileType } = useUser();

  const { height } = useWindowDimensions();
  const { width: mainContentWidth } = useMainContentDimensions();

  const {
    trans,
    data,
    rowCount,
    paging,
    onPageChange,
    isLoading,
    payload,
    setPayload,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
    refreshPage,
    setFilterResetFunc,
  } = props;
  console.log(rowCount, "loading child component");
  const { openDialog } = useDialog();

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      renderCell: (params) => {
        const filteredRow = data.filter((data) => data["radAcctId"] == params.id);
        return <Link to={"/user/details?id=" + filteredRow[0]["radUserId"]}>{params.value}</Link>;
      },
      width: 300,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 300,
    },
    {
      field: "ipAddress",
      headerName: "Ip Address",
      width: 130,
    },
    {
      field: "mac",
      headerName: "MAC",
      width: 130,
    },

    {
      field: "userPlan",
      headerName: "User Plan",
      width: 300,
    },
    {
      field: "runningPlan",
      headerName: "Running Plan",
      width: 300,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      valueGetter: (params) => moment(params).format("DD-MM-YYYY HH:mm a"),
      width: 170,
    },
    {
      field: "onlineTime",
      headerName: "Online Time",
      valueGetter: (params) => convertSecondToDays(params),
      width: 170,
    },
    {
      field: "download",
      headerName: "Downloaded",
      valueGetter: (params) => formatBytes(params),
      width: 130,
    },
    {
      field: "upload",
      headerName: "Uploaded",
      valueGetter: (params) => formatBytes(params),
      width: 130,
    },
    {
      field: "total",
      headerName: "Total",
      valueGetter: (params) => formatBytes(params),
      width: 130,
    },
    {
      field: "nasIp",
      headerName: "Nas Ip",
      width: 300,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_Filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  const disconnectUserSession = () => {
    return openDialog("disconnect_user", "Disconnect User", <DisconnectUser />);
  };

  return (
    <Card sx={{ width: "100%", maxWidth: mainContentWidth - 64 }}>
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
          justifyContent: "space-between",
          alignItems: "flex-start", // Adjust alignment as needed
        }}
        action={
          <Toolbar
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
            refreshPage={refreshPage}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
            openFilterDialog={openFilterDialog}
            disconnectUserSession={disconnectUserSession}
          />
        }
        title={
          <Typography variant="h6" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
            {trans("online_users")}
          </Typography>
        }
        subheader={<Box sx={{ display: "flex", flexWrap: "wrap" }}>{breadCrump()}</Box>}
      />
      <CardContent sx={{ height: height - 180, display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={data}
            rowCount={rowCount}
            getRowId={(row) => row[id]}
            loading={isLoading}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
            }
            sx={{
              "& .MuiDataGrid-row.even-row": {
                backgroundColor: "#f9f9f9", // Light gray for even rows
              },
              "& .MuiDataGrid-row.odd-row": {
                backgroundColor: "#ffffff", // White for odd rows
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
