import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import useReport from "../../../hooks/useReports";
import EditDelete from "../../../components/EditDelete";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import AdvanceFilter from "./AdvanceFilter";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Reports</Typography>
      <Typography>Collection Reports</Typography>
    </Breadcrumbs>
  );
};

const CustomDataGrid = (props) => {
  const { isLoadingDownload, downloadFileType } = useReport();

  const { height, width } = useWindowDimensions();
  const { width: mainContentWidth } = useMainContentDimensions();

  const navigate = useNavigate();
  const {
    trans,
    data,
    rowCount,
    paging,
    payload,
    setPayload,
    onPageChange,
    isLoading,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
    refreshPage,
    setFilterResetFunc,
  } = props;
  console.log(rowCount, "loading child component");
  const { openDialog } = useDialog();

  const columns = [
    {
      field: "action",
      headerName: "Action",
      filter: false,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          console.log(thisRow, "thisRow");
        };
        return (
          <EditDelete
            rowClick={onClick}
            clickIcon={() => viewInvoice(params.id)}
            menus={["invoice_view"]}
          />
        );
      },
    },
    {
      field: "invoiceNo",
      headerName: "Invoice No",
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 300,
    },
    {
      field: "fullName",
      headerName: "User Full Name",
      width: 300,
    },
    {
      field: "tenantName",
      headerName: "Tenant Name",
      width: 300,
    },

    {
      field: "mobileNo",
      headerName: "Mobile No",
      width: 150,
    },
    {
      field: "invoiceStatus",
      headerName: "Invoice Status",
      width: 150,
    },
    {
      field: "paymentType",
      headerName: "Payment Type",
      width: 130,
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      width: 130,
    },
    {
      field: "paidDate",
      headerName: "Paid Date",
      width: 100,
    },
    {
      field: "collectionDate",
      headerName: "Collection Date",
      width: 150,
    },
    {
      field: "collectedBy",
      headerName: "Collected By",
      width: 130,
    },
    {
      field: "invoiceAmount",
      headerName: "Invoice Amount",
      width: 150,
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      width: 150,
    },
  ];
  const viewInvoice = (id) => {
    navigate("/user/invoice", { state: { id: id } });
  };

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={
          <Toolbar
            downloadFile={downloadFile}
            openDownloadDialog={openDownloadDialog}
            refreshPage={refreshPage}
            openFilterDialog={openFilterDialog}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title="Collection Reports"
        subheader={breadCrump()}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={data}
          rowCount={rowCount}
          getRowId={(row) => row[id]}
          loading={isLoading}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
          }
          sx={{
            "& .MuiDataGrid-row.even-row": {
              backgroundColor: "#f9f9f9", // Light gray for even rows
            },
            "& .MuiDataGrid-row.odd-row": {
              backgroundColor: "#ffffff", // White for odd rows
            },
          }}
        />
      </CardContent>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  paging: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
