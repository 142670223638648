import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import { useDialog } from "../../../../contexts/DialogContext";
import useUser from "../../../../hooks/useUser";
import CustomTextField from "../../../../components/CustomTextField";
import CustomCheckBox from "../../../../components/CustomCheckBox";
import moment from "moment";

import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";

const Alert = styled(MuiAlert)(spacing);

const today = new Date();

const validationSchema = Yup.object().shape({
  onHoldFromDate: Yup.date()
    .required("Hold From Date is required")
    .typeError("Invalid date format"),
  onHoldToDate: Yup.date()
    .nullable()
    .required("Hold To Date is required")
    .typeError("Invalid date format"),
  onHoldEnabled: Yup.boolean().required("Required"),
});

const HoldUser = (props) => {
  const [hold, setHold] = useState(new Date());

  const dispatch = useDispatch();
  const { getUser, updateOnHold, user, isRenewSubmitting, addAutoRenewal } = useUser();
  const { userId, validationFields } = props;
  const currentPolicyPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  useEffect(() => {
    getUser(userId);
  }, [userId]);

  console.log(user.onHoldFromDate, "onholdonholdonhold");

  console.log(userId, "sdfghjkfg");

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  React.useEffect(() => {
    if (user && Object.keys(user).length !== 0) {
      const initialValues = {
        onHoldEnabled: user.isOnHoldEnabled,
        onHoldFromDate: moment(user.onHoldFromDate).format("MM-DD-YYYY"),
        onHoldToDate: moment(user.onHoldToDate).format("MM-DD-YYYY"),
      };

      // Reset the form with initial values
      reset(initialValues);

      // Optionally set the hold state if required
      setHold({
        from: moment(user.onHoldFromDate, "DD-MM-YYYY").toDate(),
        to: moment(user.onHoldToDate, "DD-MM-YYYY").toDate(),
      });
    }
  }, [user, reset]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        radUserId: userId,
        onHoldFromDate: moment(data.onHoldFromDate).format("DD-MM-YYYY"),
        onHoldToDate: moment(data.onHoldToDate).format("DD-MM-YYYY"),
        onHoldEnabled: data.onHoldEnabled,
      };
      await updateOnHold(payload);
      dispatch(showSuccessSnackbar("User Holded successfully!"));
      unSetDialog("hold_user");
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={2}>
              <CustomCheckBox
                label="Enable"
                name="onHoldEnabled"
                type="checkbox"
                my={2}
                fullWidth
                errors={errors}
                fields={validationSchema.fields}
              />
            </Grid>
            <Grid item md={5}>
              <CustomDatePicker
                name="onHoldFromDate"
                label="Hold From"
                range={false}
                control={control}
                fields={validationSchema.fields}
                timePicker={false}
                format="DD-MM-YYYY"
                hideSeconds={true}
                minDate={new Date()}
              />
            </Grid>
            <Grid item md={5}>
              <CustomDatePicker
                name="onHoldToDate"
                label="Hold To"
                range={false}
                control={control}
                fields={validationSchema.fields}
                timePicker={false}
                format="DD-MM-YYYY"
                hideSeconds={true}
                minDate={new Date()}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<ReplayIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Yes
            </Button>

            <Button variant="text" onClick={() => unSetDialog("hold_user")} color="primary">
              No
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default HoldUser;
