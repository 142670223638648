import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { Grid, Divider as MuiDivider, Typography as MuiTypography } from "@mui/material";
import { spacing } from "@mui/system";

import CustomDataGrid from "./CustomDataGrid";
import useReports from "../../../hooks/useReports";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import Stats from "./../../../components/Stats";
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function DueReports() {
  const { getDueReports, isLoading, dueReports, paging, downloadDuesReport } = useReports();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const currentPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);

  const [payload, setPayload] = useState(currentPayload);

  const fetchData = async () => {
    await getDueReports(payload);
  };

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
  };

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const exportFile = async (fileType) => {
    await downloadDuesReport(fileType, payload);
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  return (
    <React.Fragment>
      <Helmet title="SaaS Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h4" gutterBottom>
            Due Statistics
          </Typography>
        </Grid>
      </Grid>
      <Divider my={4} />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats title="Discount" amount={"₹" + dueReports.dueReportSum.discount} chip="Monthly" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Service Tax"
            amount={"₹" + Math.round(dueReports.dueReportSum.serviceTax)}
            chip="Annual"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Plan Amount"
            amount={"₹" + Math.round(dueReports.dueReportSum.planAmount)}
            chip="Weekly"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Payable Amount"
            amount={"₹" + Math.round(dueReports.dueReportSum.finalInvoiceAmount)}
            chip="Weekly"
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomDataGrid
            trans={t}
            addPage={null}
            editPage={null}
            refreshPage={refreshPage}
            downloadFile={exportFile}
            openDownloadDialog={null}
            id="renewId"
            data={dueReports.data}
            rowCount={dueReports.paging.totalNumberOfRecords}
            paging={{
              page: dueReports.paging.pageNumber,
              pageSize: dueReports.paging.pageSize,
            }}
            onPageChange={onPageChange}
            isLoading={isLoading}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
            setPayload={setPayload}
            payload={payload}
            setFilterResetFunc={setFilterResetFunc}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DueReports;
