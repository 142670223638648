import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
  MenuItem,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import moment from "moment";

import { useDialog } from "../../../../contexts/DialogContext";
import useUser from "../../../../hooks/useUser";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useTenant from "../../../../hooks/useTenant";
import useRate from "../../../../hooks/useRate";
import usePlan from "../../../../hooks/usePlan";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import { grey } from "@mui/material/colors";
import useScheduleUser from "../../../../hooks/useScheduleUser";

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  plan: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  rate: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  renewalCycle: Yup.string().required("Required"),
  scheduleDate: Yup.string().when("renewalCycle", {
    is: (val) => (val && val == "SCHEDULE" ? true : false),
    then: (schema) => schema.required("Schedule date is required"),
  }),
});

const RenewUser = (props) => {
  const dispatch = useDispatch();
  const { getUser, user, isRenewSubmitting, renewUser, getNextExpirationDate, nextExpirationDate } =
    useUser();
  const { autoSearchRate, rateAutoSearchResults, isLoadingAutoSearch: rateLoading } = useRate();
  const { getPlanRateWithTax, planRate } = usePlan();
  const { addScheduleUser } = useScheduleUser();

  const { userId } = props;
  const currentPlanPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const initialValues = {
    renewalCycle: "IMMEDIATE",
  };

  const { t } = useTranslation();

  const { tenantPlanGroupPlanAutoSearch, isLoadingAutoSearch, planGroupPlanAutoSearchResults } =
    useTenant();
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  const [planPayload, setPlanPayload] = useState(currentPlanPayload);
  const [planOptions, setPlanOptions] = useState([]);

  const [ratePayload, setRatePayload] = useState(currentPlanPayload);
  const [rateOptions, setRateOptions] = useState([]);
  const [oldPlanId, setOldPlanId] = useState(0);
  const [tenantId, setTenantId] = useState(0);
  const [isInitialReset, setIsInitialReset] = useState(false);
  const [buttonText, setButtonText] = useState("Renew");

  const selectedPlan = watch("plan");
  const renewalCycle = watch("renewalCycle");

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (planGroupPlanAutoSearchResults.data.length !== 0) {
      const results = [...planOptions, ...planGroupPlanAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setPlanOptions(filteredResults);
    }
  }, [planGroupPlanAutoSearchResults]);

  useEffect(() => {
    //console.log(ispOptions, "ispOptions", ispAutoSearchResults);
    if (rateAutoSearchResults.data.length !== 0) {
      const results = [...rateOptions, ...rateAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setRateOptions(filteredResults);
    }
  }, [rateAutoSearchResults]);

  const fetchPlans = async () => {
    if (user.tenantId != undefined) {
      await tenantPlanGroupPlanAutoSearch(user?.tenantId, planPayload);
    }
  };

  const fetchRates = async () => {
    await autoSearchRate(ratePayload);
  };

  const fetchNextExpirationDate = async (radUserId, rateId) => {
    await getNextExpirationDate(radUserId, rateId);
  };

  useEffect(() => {
    console.log(user, "lllllllllllllllllllllllll");
    fetchPlans();
  }, [planPayload]);

  useEffect(() => {
    if (renewalCycle == "SCHEDULE") {
      setButtonText("Schedule");
    } else {
      setButtonText("Renew");
    }
  }, [renewalCycle]);

  useEffect(() => {
    console.log(ratePayload, "ratePayload00000034");
    if (ratePayload.filters.length != 0) {
      fetchRates();
    }
  }, [ratePayload]);

  useEffect(() => {
    console.log(isInitialReset, "isInitialReset");
    if (!isInitialReset) {
      getUser(userId);
    }
  }, [userId]);

  const fetchInvoiceSummary = async (planId, rateId) => {
    await getPlanRateWithTax(planId, rateId);
  };
  const handleRateChange = (selectedValue) => {
    resetField("rate", { defaultValue: selectedValue });
    if (
      selectedPlan != null &&
      selectedValue != null &&
      Object.keys(selectedPlan).length != 0 &&
      Object.keys(selectedValue).length != 0
    ) {
      fetchInvoiceSummary(selectedPlan?.value, selectedValue?.value);
      fetchNextExpirationDate(user.radUserId, user.rateId);
    }
  };

  const handlePlanChange = (selectedValue) => {
    if (selectedValue && Object.keys(selectedValue).length != 0) {
      if (oldPlanId != selectedValue.value) {
        resetField("rate", { defaultValue: "" });
      }
      resetField("plan", { defaultValue: selectedValue });
      setRateOptions([]);
      setRatePayload({
        ...ratePayload,
        filters: [
          {
            fieldName: "planId",
            fieldValue: selectedValue?.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      });
    } else {
      setRateOptions([]);
    }
  };

  useEffect(() => {
    if (Object.keys(user).length != 0 && user.userName != "") {
      const expirationDate = moment(user.expiration, "DD-MM-YYYY hh:mm A");
      const currentDate = moment();
      const isOldDate = expirationDate.isBefore(currentDate);

      const eDate = isOldDate ? currentDate : expirationDate;
      const formattedDate = eDate.format("DD-MM-YYYY hh:mm A");

      const scheduleDateStr = eDate.format("YYYY-MM-DD HH:mm:ss");

      const initialValues = {
        plan: {
          value: user.planId,
          label: user.planName,
        },
        rate: {
          value: user.rateId,
          label: user.rateFullName,
        },
        scheduleDate: formattedDate,
        scheduleDateStr: scheduleDateStr,
      };
      console.log(initialValues, "initialValues");

      if (!isInitialReset) {
        reset(initialValues); // Reset form values
        setIsInitialReset(true); // Mark the reset as done
      }
      setOldPlanId(user.planId);
      setTenantId(user.tenantId);
      fetchNextExpirationDate(user.radUserId, user.rateId);
      fetchInvoiceSummary(user.planId, user.rateId);

      setRatePayload({
        ...ratePayload,
        filters: [
          {
            fieldName: "planId",
            fieldValue: user.planId,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      });
    } else {
      setPlanOptions([]);
    }
  }, [user, reset, isInitialReset]);

  const onSubmit = async (data) => {
    try {
      if (data.renewalCycle == "SCHEDULE") {
        const payload = {
          plan: {
            planId: data.plan.value,
            planName: data.plan.label,
          },
          rate: {
            rateId: data.rate.value,
            rateName: data.rate.label,
          },
          radUserId: userId,
          scheduleDateStr: data.scheduleDateStr,
          renewalType: "MANUAL",
          scheduleType: "MANUAL_SCHEDULE",
        };
        console.log(payload, "jkkkk");
        await addScheduleUser(payload);
        dispatch(showSuccessSnackbar("Scheduled successfully!"));
        unSetDialog("renew_user");
      } else {
        const payload = {
          radUserId: userId,
          planId: data.plan.value,
          planName: data.plan.label,
          oldPlanId: oldPlanId,
          tenantId: tenantId,
          rateId: data.rate.value,
          renewalType: "MANUAL",
          renewalCycle: data.renewalCycle,
        };
        await renewUser(payload);
        dispatch(showSuccessSnackbar("User renewed successfully!"));
        unSetDialog("renew_user");
      }
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 20 }}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <SearchableAutoComplete
                    control={control}
                    data={planOptions}
                    label={t("select_plan")}
                    payload={planPayload}
                    setPayload={setPlanPayload}
                    loading={isLoadingAutoSearch}
                    name="plan"
                    multiple={false}
                    defaultValue={""}
                    hasNextPage={false}
                    fields={validationSchema.fields}
                    onChangeEvent={handlePlanChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SearchableAutoComplete
                    control={control}
                    data={rateOptions}
                    label={t("select_rate")}
                    payload={ratePayload}
                    setPayload={setRatePayload}
                    loading={rateLoading}
                    name="rate"
                    multiple={false}
                    defaultValue={""}
                    hasNextPage={false}
                    fields={validationSchema.fields}
                    onChangeEvent={handleRateChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactHookFormSelect
                    name="renewalCycle"
                    label={t("renewal_cycle")}
                    control={control}
                    fullWidth
                    variant="outlined"
                    errors={errors}
                    fields={validationSchema.fields}
                  >
                    <MenuItem value={"IMMEDIATE"}>Immediate</MenuItem>
                    <MenuItem value={"SCHEDULE"}>Schedule</MenuItem>
                  </ReactHookFormSelect>
                </Grid>
                {renewalCycle == "SCHEDULE" && (
                  <Grid item md={12}>
                    <CustomDatePicker
                      name="scheduleDate"
                      nameTemp="scheduleDateStr"
                      label={t("schedule_date")}
                      range={false}
                      control={control}
                      fields={validationSchema.fields}
                      timePicker={true}
                      format="DD-MM-YYYY HH:mm"
                      hideSeconds={true}
                      conversionFormat="YYYY-MM-DD HH:mm:ss"
                      minDate={new Date()}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper style={{ height: "100%" }} elevation={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table>
                        {/* Table Header */}
                        <TableHead>
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              align="center"
                              sx={{
                                border: "1px solid #ccc",
                                fontWeight: "bold",
                                backgroundColor: "#ccc",
                              }}
                            >
                              Invoice Summary
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        <TableBody>
                          <TableRow key={1}>
                            <TableCell sx={{ border: "1px solid #ccc" }}>Plan Amount</TableCell>
                            <TableCell sx={{ border: "1px solid #ccc" }}>
                              {planRate?.planAmt}
                            </TableCell>
                          </TableRow>
                          <TableRow key={2}>
                            <TableCell sx={{ border: "1px solid #ccc" }}>
                              Service Tax Amount
                            </TableCell>
                            <TableCell sx={{ border: "1px solid #ccc" }}>
                              {planRate?.serviceTaxAmount}
                            </TableCell>
                          </TableRow>
                          <TableRow key={3}>
                            <TableCell sx={{ border: "1px solid #ccc" }}>
                              Total Invoice Amount
                            </TableCell>
                            <TableCell sx={{ border: "1px solid #ccc" }}>
                              {planRate?.finalInvoiceAmt}
                            </TableCell>
                          </TableRow>
                          <TableRow key={3}>
                            <TableCell sx={{ border: "1px solid #ccc" }}>
                              Next Expiry Date
                            </TableCell>
                            <TableCell sx={{ border: "1px solid #ccc" }}>
                              {nextExpirationDate}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<ReplayIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              {buttonText}
            </Button>

            <Button variant="text" onClick={() => unSetDialog("renew_user")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default RenewUser;
