import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";

import CustomTextField from "../../../../components/CustomTextField";
import CustomCheckBox from "../../../../components/CustomCheckBox";

const SmsTemplateDetail = (props) => {
  const { t } = useTranslation();
  const { fields } = props;

  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();
  return (
    <>
      <br></br>
      <Grid container spacing={6} className="row">
        <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
          <CustomTextField
            name="smsTemplateName"
            label={t("template_name")}
            control={control}
            fullWidth
            errors={errors}
            fields={fields}
          />
        </Grid>
        <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
          <CustomTextField
            name="smsTemplateCode"
            label={t("template_code")}
            control={control}
            fullWidth
            variant="outlined"
            type="text"
            errors={errors}
            fields={fields}
          />
        </Grid>
        <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
          <CustomTextField
            multiline
            label={t("content")}
            name="content"
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={fields}
          />
        </Grid>

        <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
          <ReactHookFormSelect
            name="status"
            label={t("Status")}
            control={control}
            fullWidth
            variant="outlined"
            errors={errors}
            fields={fields}
          >
            <MenuItem value={"ACTIVE"}>Active</MenuItem>

            <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
          </ReactHookFormSelect>
        </Grid>
      </Grid>
    </>
  );
};

export default SmsTemplateDetail;
