import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDialog } from "../../../../contexts/DialogContext";
import useTenant from "../../../../hooks/useTenant";
import CustomTextField from "../../../../components/CustomTextField";

import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold", // Bold text
  color: "#000", // Black text
});

const Alert = styled(MuiAlert)(spacing);

const ShowPassword = (props) => {
  const dispatch = useDispatch();
  const { getShowPass, showPass, isRenewSubmitting, updateAppPassword } = useTenant();
  const { radUserId } = props;
  let [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const methods = useForm({
    resolver: yupResolver(),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;

  const { unSetDialog, openDialog } = useDialog();

  const fetchData = async () => {
    await getShowPass(searchParams.get("id"));
  };

  console.log(searchParams.get("id"), "searrcchhh");

  useEffect(() => {
    if (searchParams && searchParams.get("id")) {
      fetchData();
    }
  }, [searchParams]);

  React.useEffect(() => {
    const initialValues = {
      showPassword: showPass,
    };
    reset(initialValues);
  }, [showPass]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Grid container spacing={12} className="row" pt={2}>
          <Grid item md={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {t("Password")}
                  </TableRow>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        borderBottom: "none",
                      }}
                    >
                      {showPass
                        ? typeof showPass === "object"
                          ? JSON.stringify(showPass)
                          : showPass
                        : "N/A"}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </FormProvider>
    </React.Fragment>
  );
};
export default ShowPassword;
