import React, { useEffect, useState, useRef } from "react";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import { addDays } from "date-fns";
import { useDialog } from "../../../../contexts/DialogContext";
import useUser from "../../../../hooks/useUser";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import useTenant from "../../../../hooks/useTenant";
import useRate from "../../../../hooks/useRate";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../../redux/slices/snackbar";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import useScheduleUser from "../../../../hooks/useScheduleUser";
import ScheduleIcon from "@mui/icons-material/Schedule";
import moment from "moment";

const Alert = styled(MuiAlert)(spacing);

const validationSchema = Yup.object().shape({
  expiryDate: Yup.string().required("Required"),
});

const ChangeValidity = (props) => {
  const [expiration, setExpiration] = useState(new Date());
  const dispatch = useDispatch();
  const { getUser, user, changeValidity, isRenewSubmitting, renewUser } = useUser();

  const { userId } = props;
  const currentPlanPayload = {
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [],
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;
  const { unSetDialog, openDialog } = useDialog();

  useEffect(() => {
    getUser(userId);
  }, [userId]);

  React.useEffect(() => {
    if (user && Object.keys(user).length != 0) {
      const initialValues = {
        expiryDate: user.expiration,
      };
      reset(initialValues);
      setExpiration(moment(user.expiration, "DD-MM-YYYY HH:mm a").toDate());
    }
  }, [user]);

  const onSubmit = async (data) => {
    console.log(data, "kjskskks");
    try {
      const payload = {
        radUserId: userId,
        expiryDate: data.expiryDate,
      };
      await changeValidity(payload);
      dispatch(showSuccessSnackbar("Validity updated successfully"));
      unSetDialog("change_validity");
      getUser(userId);
    } catch (ex) {
      dispatch(showErrorSnackbar(ex.message));
    }
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} className="row" pt={8}>
            <Grid item md={6}>
              <CustomDatePicker
                name="expiryDate"
                label="Change Validity"
                range={false}
                control={control}
                fields={validationSchema.fields}
                timePicker={true}
                format="DD-MM-YYYY HH:mm A"
                hideSeconds={true}
                conversionFormat="YYYY-MM-DD HH:mm:ss A"
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<ScheduleIcon />}
              endIcon={isRenewSubmitting ? <CircularProgress size={24} /> : null}
              disabled={false}
            >
              Change
            </Button>

            <Button variant="text" onClick={() => unSetDialog("change_validity")} color="primary">
              Close
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
export default ChangeValidity;
