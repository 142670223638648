import React from "react";
import { Box, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Last updated on Nov 27th 2024
      </Typography>
      <Typography variant="body1" paragraph>
        This privacy policy sets out how SUCCESS TELECOM PRIVATE LIMITED uses and protects any
        information that you give SUCCESS TELECOM PRIVATE LIMITED when you visit their website
        and/or agree to purchase from them.
      </Typography>
      <Typography variant="body1" paragraph>
        SUCCESS TELECOM PRIVATE LIMITED is committed to ensuring that your privacy is protected.
        Should we ask you to provide certain information by which you can be identified when using
        this website, you can be assured that it will only be used in accordance with this privacy
        statement.
      </Typography>
      <Typography variant="body1" paragraph>
        SUCCESS TELECOM PRIVATE LIMITED may change this policy from time to time by updating this
        page. You should check this page from time to time to ensure that you adhere to these
        changes.
      </Typography>

      <Typography variant="h6" gutterBottom>
        We may collect the following information:
      </Typography>
      <Box component="ul" sx={{ paddingLeft: "20px" }}>
        <Typography component="li" variant="body1">
          Name
        </Typography>
        <Typography component="li" variant="body1">
          Contact information including email address
        </Typography>
        <Typography component="li" variant="body1">
          Demographic information such as postcode, preferences, and interests, if required
        </Typography>
        <Typography component="li" variant="body1">
          Other information relevant to customer surveys and/or offers
        </Typography>
      </Box>

      <Typography variant="h6" gutterBottom>
        What we do with the information we gather
      </Typography>
      <Box component="ul" sx={{ paddingLeft: "20px" }}>
        <Typography component="li" variant="body1">
          Internal record keeping
        </Typography>
        <Typography component="li" variant="body1">
          We may use the information to improve our products and services
        </Typography>
        <Typography component="li" variant="body1">
          We may periodically send promotional emails about new products, special offers, or other
          information using the email address you have provided
        </Typography>
        <Typography component="li" variant="body1">
          From time to time, we may use your information to contact you for market research
          purposes. We may contact you by email, phone, fax, or mail.
        </Typography>
        <Typography component="li" variant="body1">
          We may use the information to customize the website according to your interests.
        </Typography>
      </Box>

      <Typography variant="h6" gutterBottom>
        How we use cookies
      </Typography>
      <Typography variant="body1" paragraph>
        A cookie is a small file that asks permission to be placed on your computer's hard drive.
        Once you agree, the file is added and the cookie helps analyze web traffic or lets you know
        when you visit a particular site. Cookies allow web applications to respond to you as an
        individual, tailoring operations to your preferences.
      </Typography>
      <Typography variant="body1" paragraph>
        We use traffic log cookies to identify which pages are being used. This helps us analyze
        data about webpage traffic and improve our website in order to tailor it to customer needs.
      </Typography>
      <Typography variant="body1" paragraph>
        Overall, cookies help us provide you with a better website, by enabling us to monitor which
        pages you find useful and which you do not. A cookie in no way gives us access to your
        computer or any information about you, other than the data you choose to share with us.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Controlling your personal information
      </Typography>
      <Box component="ul" sx={{ paddingLeft: "20px" }}>
        <Typography component="li" variant="body1">
          Whenever you are asked to fill in a form on the website, look for the box to indicate you
          do not want the information used for direct marketing purposes.
        </Typography>
        <Typography component="li" variant="body1">
          If you have previously agreed to us using your personal information for direct marketing
          purposes, you may change your mind at any time by writing to or emailing us.
        </Typography>
        <Typography component="li" variant="body1">
          We will not sell, distribute, or lease your personal information to third parties unless
          required by law.
        </Typography>
        <Typography component="li" variant="body1">
          If you believe any information we hold on you is incorrect or incomplete, please write to
          us at No:01, Muppadai Nagar, Vellanoor, Avadi Tiruvallur TAMIL NADU 600062. We will
          promptly correct any errors.
        </Typography>
      </Box>

      <Typography variant="caption" display="block" mt={2}>
        Disclaimer: The above content is created at SUCCESS TELECOM PRIVATE LIMITED's sole
        discretion. Razorpay shall not be liable for any content provided here and shall not be
        responsible for any claims and liability that may arise due to the merchant’s non-adherence
        to it.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
