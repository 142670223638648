export const PlanConstants = {
  FETCH_PLAN_REQUEST: "FETCH_PLAN_REQUEST",
  FETCH_PLAN_SUCCESS: "FETCH_PLAN_SUCCESS",
  FETCH_PLAN_FAILURE: "FETCH_PLAN_FAILURE",
  ADD_PLAN_REQUEST: "ADD_PLAN_REQUEST",
  ADD_PLAN_SUCCESS: "ADD_PLAN_SUCCESS",
  ADD_PLAN_FAILURE: "ADD_PLAN_FAILURE",
  PLAN_SEARCH_REQUEST: "PLAN_SEARCH_REQUEST",
  PLAN_SEARCH_SUCCESS: "PLAN_SEARCH_SUCCESS",
  PLAN_SEARCH_FAILURE: "PLAN_SEARCH_FAILURE",
  PLAN_AUTO_SEARCH_REQUEST: "PLAN_AUTO_SEARCH_REQUEST",
  PLAN_AUTO_SEARCH_SUCCESS: "PLAN_AUTO_SEARCH_SUCCESS",
  PLAN_AUTO_SEARCH_FAILURE: "PLAN_AUTO_SEARCH_FAILURE",
  GET_PLAN_REQUEST: "GET_PLAN_REQUEST",
  GET_PLAN_SUCCESS: "GET_PLAN_SUCCESS",
  GET_PLAN_FAILURE: "GET_PLAN_FAILURE",
  UPDATE_PLAN_REQUEST: "UPDATE_PLAN_REQUEST",
  UPDATE_PLAN_SUCCESS: "UPDATE_PLAN_SUCCESS",
  UPDATE_PLAN_FAILURE: "UPDATE_PLAN_FAILURE",
  DOWNLOAD_PLAN_REQUEST: "DOWNLOAD_PLAN_REQUEST",
  DOWNLOAD_PLAN_SUCCESS: "DOWNLOAD_PLAN_SUCCESS",
  DOWNLOAD_PLAN_FAILURE: "DOWNLOAD_PLAN_FAILURE",
  UPLOAD_PLAN_REQUEST: "UPLOAD_PLAN_REQUEST",
  UPLOAD_PLAN_SUCCESS: "UPLOAD_PLAN_SUCCESS",
  UPLOAD_PLAN_FAILURE: "UPLOAD_PLAN_FAILURE",
  GET_PLAN_RATE_REQUEST: "GET_PLAN_RATE_REQUEST",
  GET_PLAN_RATE_SUCCESS: "GET_PLAN_RATE_SUCCESS",
  GET_PLAN_RATE_FAILURE: "GET_PLAN_RATE_FAILURE",
};
