import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useTenant from "../../../../hooks/useTenant";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import FilterReset from "../../../../components/FilterReset";
import { searchPayload, convertedValue, invoiceStatusOptions } from "../../../../utils/common";
import { OperatorConstants } from "../../../../constants/operator.constants";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import CustomTextField from "../../../../components/CustomTextField";
import { useDialog } from "../../../../contexts/DialogContext";
import usePlan from "../../../../hooks/usePlan";
import CustomDatePicker from "../../../../components/CustomDatePicker";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const statusOptions = [
  {
    label: "Payment Pending",
    value: "PP",
  },
  {
    label: "Un Paid",
    value: "UP",
  },
  {
    label: "Paid",
    value: "PA",
  },
  {
    label: "Canelled",
    value: "CA",
  },
];

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    userNameOperator: "eq",
    planNameOperator: "eq",
    userName: "",
    planName: [],
    activateDateOperator: "btn",
    activateDate: "",
  };

  const { fields, payload, setPayload, setFilterResetFunc } = props;

  const { autoSearchPlan, planAutoSearchResults, isLoadingAutoSearch } = usePlan();

  const [planPayload, setPlanPayload] = useState(currentPayload);
  const [planOptions, setPlanOptions] = useState([]);
  const [planMultiValue, setPlanMultiValue] = useState(false);
  const [defaultPlan, setDefaultPlan] = useState([]);

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    setFilterResetFunc(() => reset);
  }, [reset]);

  const planOpValue = watch("planNameOperator");

  const onSubmit = async (data) => {
    try {
      let filters = [];

      if (data.userName != "" && data.userName != null) {
        filters.push({
          fieldName: "userName",
          fieldValue: data.userName,
          op: data.userNameOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }

      if (data.planName != null && Object.keys(data.planName).length != 0) {
        filters.push({
          fieldName: "planId",
          fieldValue: convertedValue(data.planName, "value").join(","),
          op: data.planNameOperator,
          dataType: "int",
          condition: "and",
          isJoin: true,
          entityName: "PlanEntity",
          attributeName: "plan",
        });
      }
      if (data.activateDate != "" && data.activateDate != null) {
        filters.push({
          fieldName: "activateDate",
          fieldValue: data.activateDateTemp,
          op: data.activateDateOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.invoiceDate != "" && data.invoiceDate != null) {
        filters.push({
          fieldName: "invoiceDate",
          fieldValue: data.invoiceDateStr,
          op: data.invoiceDateOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.error(err, "errerrerrerr");
    }
  };

  useEffect(() => {
    if (planOpValue == "in" || planOpValue == "nin") {
      setDefaultPlan([]);
      setValue("planName", []);
      setPlanMultiValue(true);
    } else {
      setDefaultPlan({});
      setValue("planName", {});
      setPlanMultiValue(false);
    }
  }, [planOpValue]);

  const fetchPlan = async () => {
    await autoSearchPlan(planPayload);
  };
  useEffect(() => {
    fetchPlan();
  }, [planPayload]);

  useEffect(() => {
    if (planAutoSearchResults.data.length !== 0) {
      setPlanOptions([...planOptions, ...planAutoSearchResults.data]);
    }
  }, [planAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="userNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("user_name")}
                name="userName"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="activateDateOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.date).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomDatePicker
                name="activateDate"
                nameTemp="activateDateTemp"
                label="Trans Date"
                range={true}
                control={control}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="planNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <SearchableAutoComplete
                control={control}
                data={planOptions}
                label="Plan Name"
                payload={planPayload}
                setPayload={setPlanPayload}
                loading={isLoadingAutoSearch}
                name="planName"
                multiple={planMultiValue}
                defaultValue={defaultPlan}
              />
            </Grid>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="invoiceDateOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.date).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomDatePicker
                name="invoiceDate"
                nameTemp="invoiceDateStr"
                label="Invoice Date From"
                range={true}
                control={control}
              />
            </Grid>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="invoiceDateOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.date).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomDatePicker
                name="invoiceDate"
                nameTemp="invoiceDateStr"
                label="Invoice Date To"
                range={true}
                control={control}
              />
            </Grid>
          </Grid>
          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
