import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";
import useNas from "../../../../hooks/useNas";
import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import CustomCheckBox from "../../../../components/CustomCheckBox";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function MkRadiusFields(props) {
  const { t } = useTranslation();
  const { fields } = props;
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { autoSearchNas, nasAutoSearchResults } = useNas();

  const [nasOptions, setNasOptions] = useState([]);
  const [nasPayload, setNasPayload] = useState(currentPayload);

  const fetchNas = async () => {
    await autoSearchNas(nasPayload);
  };
  useEffect(() => {
    fetchNas();
  }, [nasPayload]);

  useEffect(() => {
    console.log(nasAutoSearchResults, "nasAutoSearchResults");
    if (nasAutoSearchResults && nasAutoSearchResults.data.length !== 0) {
      const results = [...nasOptions, ...nasAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setNasOptions(filteredResults);
    }
  }, [nasAutoSearchResults]);

  return (
    <Grid container spacing={6} className="row">
      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <SearchableAutoComplete
          name="nas"
          label={t("nas")}
          control={control}
          data={nasOptions}
          payload={nasPayload}
          setPayload={setNasPayload}
          loading={false}
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={fields}
          fullWidth
          variant="outlined"
          errors={errors}
        ></SearchableAutoComplete>
      </Grid>

      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <i>Services</i>
        <CustomCheckBox
          label={t("ppp")}
          name="ppp"
          type="checkbox"
          my={2}
          fullWidth
          errors={errors}
          fields={fields}
        />
        <CustomCheckBox
          label={t("hotspot")}
          name="hotspot"
          type="checkbox"
          my={2}
          fullWidth
          errors={errors}
          fields={fields}
        />
      </Grid>

      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <CustomTextField
          label={t("address")}
          name="address"
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        />
      </Grid>
      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <CustomTextField
          label={t("secret")}
          name="secret"
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        />
      </Grid>
      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          variant="outlined"
          fields={fields}
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
}

export default MkRadiusFields;
