import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";

import CustomTextField from "../../../../components/CustomTextField";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";

const NasFields = React.memo((props) => {
  const { fields } = props;

  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext({
    mode: "onChange",
  });

  const nasType = watch("nasType");

  return (
    <Grid container spacing={6} className="row">
      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <CustomTextField
          label={t("nas_name")}
          name="nasName"
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        />
      </Grid>
      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <CustomTextField
          label={t("short_name")}
          name="shortName"
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        />
      </Grid>
      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <CustomTextField
          label={t("secret_name")}
          name="secret"
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        />
      </Grid>
      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <ReactHookFormSelect
          name="nasType"
          label={t("type_name")}
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        >
          <MenuItem value={"Mikrotik"}>Mikrotik</MenuItem>
          <MenuItem value={"Another"}>Another</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      {nasType == "Mikrotik" && (
        <>
          <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
            <CustomTextField
              label={t("api_username")}
              name="apiUserName"
              control={control}
              fullWidth
              fields={fields}
              variant="outlined"
              errors={errors}
            />
          </Grid>
          <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
            <CustomTextField
              label={t("api_password")}
              name="apiPassword"
              control={control}
              fullWidth
              fields={fields}
              variant="outlined"
              errors={errors}
            />
          </Grid>
          <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
            <CustomTextField
              label={t("api_port")}
              name="mkApiPort"
              control={control}
              fullWidth
              fields={fields}
              variant="outlined"
              errors={errors}
            />
          </Grid>
        </>
      )}
      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <ReactHookFormSelect
          name="status"
          label={t("Status")}
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        >
          <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
          <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <CustomTextField
          label={t("notes")}
          name="notes"
          control={control}
          fullWidth
          fields={fields}
          variant="outlined"
          errors={errors}
        />
      </Grid>
    </Grid>
  );
});
export default NasFields;
