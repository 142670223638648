import React from "react";
import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Paper as MuiPaper,
  CardContent,
  Box,
  Chip,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import EditDelete from "../../../../components/EditDelete";
import Toolbar from "../../../../components/Toolbar";
import RenewUser from "./RenewUser";
import { useDialog } from "../../../../contexts/DialogContext";
import { getTimeStamp } from "../../../../utils/common";
import useWindowDimensions from "../../../../utils/windowDimensions";
import AdvanceFilter from "./AdvanceFilter";
import useUser from "../../../../hooks/useUser";
import useMainContentDimensions from "../../../../utils/mainContentDimensions";
import AssignPlan from "./AssignPlan";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Masters</Typography>
      <Typography>User List</Typography>
      <Typography>List</Typography>
    </Breadcrumbs>
  );
};

const userStatus = (status) => {
  if (status == "active") {
    return <Chip label="Active" color="primary" />;
  } else if (status == "online") {
    return <Chip label="Online" color="success" />;
  } else if (status == "expired") {
    return <Chip label="Expired" color="warning" />;
  } else if (status == "offline") {
    return <Chip label="Offline" sx={{ backgroundColor: "grey.500", color: "white" }} />;
  }
};

const CustomDataGrid = (props) => {
  const { isLoadingDownload, downloadFileType } = useUser();

  const { height, width } = useWindowDimensions();
  const { width: mainContentWidth } = useMainContentDimensions();

  const {
    editPage,
    addPage,
    trans,
    rows,
    rowCount,
    payload,
    setPayload,
    isLoading,
    id,
    handleSortModelChange,
    sortModel,
    downloadFile,
    openDownloadDialog,
    refreshPage,
    setFilterResetFunc,
    paginationModel,
    paginationMeta,
    paging,
    onPaginationModelChange,
  } = props;
  console.log(rowCount, "loading child component");

  const { openDialog } = useDialog();

  const renewClick = (id) => {
    return openDialog("renew_user", "Renew User", <RenewUser key={getTimeStamp()} userId={id} />);
  };

  const assignClick = (id) => {
    return openDialog(
      "assign_plan",
      "Assign plan",
      <AssignPlan key={getTimeStamp()} userId={id} />,
    );
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      filter: false,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          console.log(thisRow, "thisRow");
        };
        return (
          <EditDelete
            rowClick={onClick}
            editPage={() => editPage(params.id)}
            renewClick={() => renewClick(params.id)}
            assignClick={() => assignClick(params.id)}
            menus={["edit", "renew", "assign_plan", "delete"]}
          />
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      renderCell: (params) => <Link to={"/user/details?id=" + params.id}>{params.value}</Link>,
      width: 200,
    },
    {
      field: "userStatus",
      headerName: "Status",
      renderCell: (params) => {
        return userStatus(params.row["userStatus"]);
      },
      width: 200,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 200,
    },
    {
      field: "emailId",
      headerName: "Email Id",
      width: 200,
    },
    {
      field: "mobileNo",
      headerName: "Mobile No",
      width: 130,
    },
    {
      field: "planName",
      headerName: "Plan Name",
      width: 200,
    },
    {
      field: "rateName",
      headerName: "Rate",
      width: 200,
    },
    {
      field: "expiration",
      headerName: "Expiration",
      width: 200,
    },
    {
      field: "quotaLimit",
      headerName: "Data Limit",
      width: 200,
    },
    {
      field: "tenantName",
      headerName: "Tenant Name",
      width: 200,
    },
    {
      field: "activationDate",
      headerName: "Activation Date",
      width: 130,
    },
    {
      field: "expiredDate",
      headerName: "Last Expired Date",
      width: 200,
    },
    {
      field: "renewDate",
      headerName: "Last Renew Date",
      width: 200,
    },
    {
      field: "regDate",
      headerName: "Registered Date",
      width: 200,
    },
    {
      field: "address",
      headerName: "Address",
      width: 200,
    },
    {
      field: "mac",
      headerName: "Mac",
      width: 200,
    },
    {
      field: "logoffTime",
      headerName: "Last Logoff Time",
      width: 200,
    },
    {
      field: "connectionType",
      headerName: "Connection Type",
      width: 200,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        openFilterDialog={openFilterDialog}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: "100%", maxWidth: mainContentWidth - 64 }}>
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
          justifyContent: "space-between",
          alignItems: "flex-start", // Adjust alignment as needed
        }}
        action={
          <Toolbar
            addPage={addPage}
            downloadFile={downloadFile}
            openFilterDialog={openFilterDialog}
            openDownloadDialog={openDownloadDialog}
            refreshPage={refreshPage}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title={
          <Typography variant="h6" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
            {trans("user_list")}
          </Typography>
        }
        subheader={<Box sx={{ display: "flex", flexWrap: "wrap" }}>{breadCrump()}</Box>}
      />
      <CardContent sx={{ height: height - 180, display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, overflow: "auto" }}>
          <DataGrid
            checkboxSelection
            initialState={{
              pagination: { paginationModel: { pageSize: 100 } },
            }}
            rowCount={rowCount}
            getRowId={(row) => row[id]}
            columns={columns}
            rows={rows}
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMeta={paginationMeta}
            loading={isLoading}
            pageSizeOptions={[25, 50, 100]}
            paginationMode="server"
            onPaginationModelChange={onPaginationModelChange}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            paginationModel={paginationModel}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
            }
            sx={{
              "& .MuiDataGrid-row.even-row": {
                backgroundColor: "#f9f9f9", // Light gray for even rows
              },
              "& .MuiDataGrid-row.odd-row": {
                backgroundColor: "#ffffff", // White for odd rows
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
CustomDataGrid.propTypes = {
  editPage: PropTypes.func.isRequired,
  addPage: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSortModelChange: PropTypes.func.isRequired,
  sortModel: PropTypes.object.isRequired,
};
export default CustomDataGrid;
