import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDialog } from "../contexts/DialogContext";
import useUser from "../hooks/useUser";
import CustomTextField from "../components/CustomTextField";

import { showSuccessSnackbar, showErrorSnackbar } from "../redux/slices/snackbar";

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold", // Bold text
  color: "#000", // Black text
});

const Alert = styled(MuiAlert)(spacing);

const Terms = (props) => {
  const dispatch = useDispatch();
  const {
    getShowAppPassword,
    getShowPassword,
    showPass,
    showAppPass,
    isRenewSubmitting,
    updateAppPassword,
  } = useUser();
  const { radUserId } = props;

  const { t } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();

  const methods = useForm({
    resolver: yupResolver(),
  });
  const {
    reset,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
    control,
  } = methods;

  const { unSetDialog, openDialog } = useDialog();

  const fetchData = async () => {
    await getShowAppPassword(searchParams.get("id"));
  };

  console.log(searchParams.get("id"), "searrcchhh");

  useEffect(() => {
    if (searchParams && searchParams.get("id")) {
      fetchData();
    }
  }, [searchParams]);

  React.useEffect(() => {
    const initialValues = {
      showAppPassword: showAppPass,
    };
    reset(initialValues);
  }, [showAppPass]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Grid container spacing={12} className="row" pt={2}>
          <Grid item md={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "none", fontWeight: "bold" }}>
                      Last updated on Nov 27th 2024
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{ paddingTop: "5px", borderBottom: "none" }}>
                      <Typography>
                        For the purpose of these Terms and Conditions, The term "we", "us", "our"
                        used anywhere on this page shall mean SUCCESS TELECOM PRIVATE LIMITED, whose
                        registered/operational office is No:01, Muppadai Nagar, Vellanoor, Avadi
                        Tiruvallur TAMIL NADU 600062 . "you", “your”, "user", “visitor” shall mean
                        any natural or legal person who is visiting our website and/or agreed to
                        purchase from us.
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ paddingTop: "5px", borderBottom: "none", fontWeight: "bold" }}>
                      Your use of the website and/or purchase from us are governed by following
                      Terms and Conditions:
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        paddingTop: "5px",
                        paddingLeft: "20px", // Adds left spacing
                        paddingRight: "20px",
                        borderBottom: "none",
                        display: "flex", // Allows alignment of the bullet with text
                        alignItems: "flex-start",
                      }}
                    >
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>•</span>{" "}
                      {/* Bullet point */}
                      The content of the pages of this website is subject to change without notice.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        paddingTop: "5px",
                        paddingLeft: "20px", // Adds left spacing
                        paddingRight: "20px",
                        borderBottom: "none",
                        display: "flex", // Allows alignment of the bullet with text
                        alignItems: "flex-start",
                      }}
                    >
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>•</span>{" "}
                      {/* Bullet point */}
                      Neither we nor any third parties provide any warranty or guarantee as to the
                      accuracy, timeliness, performance, completeness or suitability of the
                      information and materials found or offered on this website for any particular
                      purpose. You acknowledge that such information and materials may contain
                      inaccuracies or errors and we expressly exclude liability for any such
                      inaccuracies or errors to the fullest extent permitted by law.{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        paddingTop: "5px",
                        paddingLeft: "20px", // Adds left spacing
                        paddingRight: "20px",
                        borderBottom: "none",
                        display: "flex", // Allows alignment of the bullet with text
                        alignItems: "flex-start",
                      }}
                    >
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>•</span>{" "}
                      {/* Bullet point */}
                      Your use of any information or materials on our website and/or product pages
                      is entirely at your own risk, for which we shall not be liable. It shall be
                      your own responsibility to ensure that any products, services or information
                      available through our website and/or product pages meet your specific
                      requirements.{" "}
                    </TableCell>{" "}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        paddingTop: "5px",
                        paddingLeft: "20px", // Adds left spacing
                        paddingRight: "20px",
                        borderBottom: "none",
                        display: "flex", // Allows alignment of the bullet with text
                        alignItems: "flex-start",
                      }}
                    >
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>•</span>{" "}
                      {/* Bullet point */}
                      Our website contains material which is owned by or licensed to us. This
                      material includes, but are not limited to, the design, layout, look,
                      appearance and graphics. Reproduction is prohibited other than in accordance
                      with the copyright notice, which forms part of these terms and conditions.{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingTop: "0px",
                        paddingLeft: "20px", // Adds left spacing
                        paddingRight: "20px",
                        borderBottom: "none",
                        display: "flex", // Allows alignment of the bullet with text
                        alignItems: "flex-start",
                      }}
                    >
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>•</span>{" "}
                      {/* Bullet point */}
                      All trademarks reproduced in our website which are not the property of, or
                      licensed to, the operator are acknowledged on the website.{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingTop: "0px",
                        paddingLeft: "20px", // Adds left spacing
                        paddingRight: "20px",
                        borderBottom: "none",
                        display: "flex", // Allows alignment of the bullet with text
                        alignItems: "flex-start",
                      }}
                    >
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>•</span>{" "}
                      {/* Bullet point */}
                      Unauthorized use of information provided by us shall give rise to a claim for
                      damages and/or be a criminal offense.{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingTop: "0px",
                        paddingLeft: "20px", // Adds left spacing
                        paddingRight: "20px",
                        borderBottom: "none",
                        display: "flex", // Allows alignment of the bullet with text
                        alignItems: "flex-start",
                      }}
                    >
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>•</span>{" "}
                      {/* Bullet point */}
                      From time to time our website may also include links to other websites. These
                      links are provided for your convenience to provide further information.{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingTop: "0px",
                        paddingLeft: "20px", // Adds left spacing
                        paddingRight: "20px",
                        borderBottom: "none",
                        display: "flex", // Allows alignment of the bullet with text
                        alignItems: "flex-start",
                      }}
                    >
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>•</span>{" "}
                      {/* Bullet point */}
                      You may not create a link to our website from another website or document
                      without SUCCESS TELECOM PRIVATE LIMITED’s prior written consent.{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingTop: "0px",
                        paddingLeft: "20px", // Adds left spacing
                        paddingRight: "20px",
                        borderBottom: "none",
                        display: "flex", // Allows alignment of the bullet with text
                        alignItems: "flex-start",
                      }}
                    >
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>•</span>{" "}
                      {/* Bullet point */}
                      Any dispute arising out of use of our website and/or purchase with us and/or
                      any engagement with us is subject to the laws of India .{" "}
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingTop: "0px",
                        paddingLeft: "20px", // Adds left spacing
                        paddingRight: "20px",
                        borderBottom: "none",
                        display: "flex", // Allows alignment of the bullet with text
                        alignItems: "flex-start",
                      }}
                    >
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>•</span>{" "}
                      {/* Bullet point */}
                      We, shall be under no liability whatsoever in respect of any loss or damage
                      arising directly or indirectly out of the decline of authorization for any
                      Transaction, on Account of the Cardholder having exceeded the preset limit
                      mutually agreed by us with our acquiring bank from time to time
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </FormProvider>
    </React.Fragment>
  );
};
export default Terms;
