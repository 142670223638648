import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { useTranslation } from "react-i18next";
import EditDelete from "../../../components/EditDelete";

import {
  Card,
  Grid,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import useMainContentDimensions from "../../../utils/mainContentDimensions";
import useUser from "../../../hooks/useUser";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const breadCrump = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Typography>Reports</Typography>
      <Typography>Material Reports</Typography>
    </Breadcrumbs>
  );
};

function CustomDataGrid(props) {
  const { width: mainContentWidth } = useMainContentDimensions();

  const { state } = useLocation();
  const { t } = useTranslation();
  const {
    addPage,
    setSelectedRows,
    editPage,
    rowCount,
    refreshPage,
    setFilterResetFunc,
    isLoading,
  } = props;

  const currentPayload = {
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const {
    getAllUserMaterial,
    userMaterials,
    paging,
    id,
    downloadFile,
    isLoadingDownload,
    downloadFileType,
  } = useUser();
  const { openDialog } = useDialog();

  const [payload, setPayload] = useState(currentPayload);

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const handleSelectedRows = (GridSelectionModelChangeParams) => {
    let selectedValues = [];
    if (Array.isArray(GridSelectionModelChangeParams)) {
      GridSelectionModelChangeParams.forEach((selection_index) => {
        const filteredValues = userMaterials.filter(function (data) {
          return data.tenantId === selection_index;
        });
        selectedValues = [...selectedValues, ...filteredValues];
      });
    }
    setSelectedRows(selectedValues);
  };

  console.log(rowCount, "loading child component");
  const columns = [
    {
      field: "material",
      headerName: "Material Name",
      valueGetter: (params) => params.materialName,
      width: 180,
    },
    {
      field: "qty",
      headerName: "Quantity",
      width: 180,
    },
    {
      field: "uom",
      headerName: "Unit of Measurements",
      width: 180,
    },
  ];

  return (
    <Card sx={{ width: "100%", maxWidth: mainContentWidth - 64 }}>
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
          justifyContent: "space-between",
          alignItems: "flex-start", // Adjust alignment as needed
        }}
        action={
          <Toolbar
            addPage={addPage}
            editPage={editPage}
            downloadFile={downloadFile}
            refreshPage={refreshPage}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
          />
        }
        title={
          <Typography variant="h6" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
            {t("material_report")}
          </Typography>
        }
        subheader={<Box sx={{ display: "flex", flexWrap: "wrap" }}>{breadCrump()}</Box>}
      />
      <CardContent sx={{ height: height - 180, display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={userMaterials}
            getRowId={(row) => row["id"]}
            rowCount={rowCount}
            loading={isLoading}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            onSelectionModelChange={handleSelectedRows}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
            }
            sx={{
              "& .MuiDataGrid-row.even-row": {
                backgroundColor: "#f9f9f9", // Light gray for even rows
              },
              "& .MuiDataGrid-row.odd-row": {
                backgroundColor: "#ffffff", // White for odd rows
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default CustomDataGrid;
