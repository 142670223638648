import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDialog } from "../../../contexts/DialogContext";
import { getTimeStamp } from "../../../utils/common";
import { useTranslation } from "react-i18next";
import useMainContentDimensions from "../../../utils/mainContentDimensions";

import {
  Card,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../components/Toolbar";
import useWindowDimensions from "../../../utils/windowDimensions";
import useAuthenticationLog from "../../../hooks/useAuthenticationLog";
import moment from "moment";
import { convertSecondToDays, formatBytes } from "../../../utils/common";
import useUser from "../../../hooks/useUser";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function AuthenticationLogs() {
  const { t } = useTranslation();

  const { user, getUser } = useUser();

  let [searchParams, setSearchParams] = useSearchParams();

  const userId = searchParams.get("id");

  const currentPayload = {
    filters: [
      {
        fieldName: "userName",
        fieldValue: user.userName,
        op: "eq",
        dataType: "int",
        condition: "and",
        isJoin: true,
        attributeName: "radUser",
        entityName: "RadUserEntity",
      },
    ],
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortFields: [
      {
        field: "id",
        sort: "DESC",
      },
    ],
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getAllAuthenticationLog, authenticationLogs, paging, downloadFile } =
    useAuthenticationLog();
  const { openDialog } = useDialog();
  const { width: mainContentWidth } = useMainContentDimensions();

  const [payload, setPayload] = useState(currentPayload);

  useEffect(() => {
    getUser(userId);
  }, [userId]);

  const fetchData = async () => {
    await getAllAuthenticationLog(payload);
  };

  const [filterResetFunc, setFilterResetFunc] = useState(
    () => () => console.log("Refresh initialized"),
  );

  const refreshPage = () => {
    setPayload(currentPayload);
    filterResetFunc();
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
  };

  const onPageChange = (newPaginationModel) => {
    console.log(newPaginationModel, "pagepage");
    const pagination = {
      pageNumber: newPaginationModel.page == 0 ? 1 : newPaginationModel.page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
    setPaginationModel(newPaginationModel);
  };

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });

  // const breadCrump = () => {
  //   return (
  //     <Breadcrumbs aria-label="Breadcrumb" mt={2}>
  //       <Typography>Invoices</Typography>
  //       <Typography>Paid</Typography>
  //     </Breadcrumbs>
  //   );
  // };

  useEffect(() => {
    fetchData();
  }, [payload]);

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "authDate",
      headerName: "Authentication Date",
      valueGetter: (params) => moment(params).format("DD-MM-YYYY HH:mm a"),
      width: 200,
    },
    {
      field: "reply",
      headerName: "Reply",
      width: 400,
    },
  ];

  return (
    <Card sx={{ width: mainContentWidth - 64 }}>
      <CardHeader
        action={<Toolbar downloadFile={downloadFile} refreshPage={refreshPage} />}
        title={t("authentication_logs")}
        // subheader={breadCrump()}
        downloadFile={downloadFile}
      />
      <CardContent sx={{ height: height - 180 }}>
        <DataGrid
          columns={columns}
          rows={authenticationLogs}
          getRowId={(row) => row["id"]}
          rowCount={paging.totalNumberOfRecords}
          loading={false}
          rowsPerPageOptions={[5]}
          pagination
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          paginationMode="server"
          onPageChange={(page) => onPageChange(page)}
          onPageSizeChange={(pageSize) => onPageChange(pageSize)}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
          }
          sx={{
            "& .MuiDataGrid-row.even-row": {
              backgroundColor: "#f9f9f9", // Light gray for even rows
            },
            "& .MuiDataGrid-row.odd-row": {
              backgroundColor: "#ffffff", // White for odd rows
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

export default AuthenticationLogs;
