import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Alert as MuiAlert,
  Box,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import ReplayIcon from "@mui/icons-material/Replay";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDialog } from "../contexts/DialogContext";
import useUser from "../hooks/useUser";
import CustomTextField from "../components/CustomTextField";

import { showSuccessSnackbar, showErrorSnackbar } from "../redux/slices/snackbar";

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold", // Bold text
  color: "#000", // Black text
});

const Alert = styled(MuiAlert)(spacing);

const CancellationRefundPolicy = (props) => {
  const { unSetDialog, openDialog } = useDialog();

  return (
    <Box sx={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Cancellation & Refund Policy
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Last updated on Nov 27th 2024
      </Typography>
      <Typography variant="body1" paragraph>
        SUCCESS TELECOM PRIVATE LIMITED believes in helping its customers as far as possible, and
        has therefore a liberal cancellation policy. Under this policy:
      </Typography>
      <Box component="ul" sx={{ paddingLeft: "20px" }}>
        <Typography component="li" variant="body1">
          Cancellations will be considered only if the request is made within 3-5 days of placing
          the order. However, the cancellation request may not be entertained if the orders have
          been communicated to the vendors/merchants and they have initiated the process of shipping
          them.
        </Typography>
        <Typography component="li" variant="body1">
          SUCCESS TELECOM PRIVATE LIMITED does not accept cancellation requests for perishable items
          like flowers, eatables, etc. However, refund/replacement can be made if the customer
          establishes that the quality of the product delivered is not good.
        </Typography>
        <Typography component="li" variant="body1">
          In case of receipt of damaged or defective items, please report the same to our Customer
          Service team. The request will, however, be entertained once the merchant has checked and
          determined the same at his own end. This should be reported within 3-5 days of receipt of
          the products.
        </Typography>
        <Typography component="li" variant="body1">
          In case you feel that the product received is not as shown on the site or as per your
          expectations, you must bring it to the notice of our customer service within 3-5 days of
          receiving the product. The Customer Service Team, after looking into your complaint, will
          take an appropriate decision.
        </Typography>
        <Typography component="li" variant="body1">
          In case of complaints regarding products that come with a warranty from manufacturers,
          please refer the issue to them.
        </Typography>
        <Typography component="li" variant="body1">
          In case of any Refunds approved by the SUCCESS TELECOM PRIVATE LIMITED, it’ll take 3-5
          days for the refund to be processed to the end customer.
        </Typography>
      </Box>
      <Typography variant="caption" display="block" mt={2}>
        Disclaimer: The above content is created at SUCCESS TELECOM PRIVATE LIMITED's sole
        discretion. Razorpay shall not be liable for any content provided here and shall not be
        responsible for any claims and liability that may arise due to merchant’s non-adherence to
        it.
      </Typography>
    </Box>
  );
};
export default CancellationRefundPolicy;
