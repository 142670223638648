import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "../../../../contexts/DialogContext";
import { useTranslation } from "react-i18next";
import useMainContentDimensions from "../../../../utils/mainContentDimensions";

import {
  Card,
  Grid,
  CardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Button,
  Typography,
  CardContent,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import Toolbar from "../../../../components/Toolbar";
import useWindowDimensions from "../../../../utils/windowDimensions";
import useSmsToTenant from "../../../../hooks/useSmsToTenant";
import AdvanceFilter from "./AdvanceFilter";
import SendMessage from "./MessageFields";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function CustomDataGrid(props) {
  const { isLoadingDownload, downloadFileType } = useSmsToTenant();
  const { width: mainContentWidth } = useMainContentDimensions();

  const { state } = useLocation();
  const { t } = useTranslation();
  const { addPage, refreshPage, setFilterResetFunc } = props;
  const [selectedRows, setSelectedRows] = useState([]);

  const addTo = (id) => {
    return openDialog("send_message", "Send Message", <SendMessage selectedRows={selectedRows} />);
  };

  const currentPayload = {
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
  };

  const [sortModel, setSortModel] = useState([
    {
      field: "updatedAt",
      sort: "desc",
    },
  ]);
  const { height } = useWindowDimensions();
  const { getAllSmsToTenant, smsToTenants, paging, downloadFile } = useSmsToTenant();
  const { openDialog } = useDialog();

  const [payload, setPayload] = useState(currentPayload);

  const onPageChange = (page) => {
    console.log(page, "pagepage");
    const pagination = {
      pageNumber: page == 0 ? 1 : page + 1,
      pageSize: payload.pagination.pageSize,
    };
    setPayload((prev) => ({ ...prev, pagination }));
  };

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Sms</Typography>
        <Typography>SmsToTenant</Typography>
      </Breadcrumbs>
    );
  };

  const handleSortModelChange = (newModel) => {
    console.log(newModel, "newModel");
    if (newModel.length !== 0) {
      setSortModel(newModel);
      setPayload((prev) => ({ ...prev, sortFields: newModel }));
    }
  };

  const handleSelectedRows = (GridSelectionModelChangeParams) => {
    let selectedValues = [];
    if (Array.isArray(GridSelectionModelChangeParams)) {
      GridSelectionModelChangeParams.forEach((selection_index) => {
        const filteredValues = smsToTenants.filter(function (data) {
          return data.tenantId === selection_index;
        });
        selectedValues = [...selectedValues, ...filteredValues];
      });
    }
    setSelectedRows(selectedValues);
  };

  const columns = [
    {
      field: "tenantName",
      headerName: "Franchise Name",
      width: 300,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 300,
    },
    {
      field: "emailId",
      headerName: "Mail Id",
      width: 300,
    },
    {
      field: "mobileNo",
      headerName: "Mobile No",
      width: 130,
    },
  ];

  const openFilterDialog = () => {
    return openDialog(
      "advance_filter",
      "Advance Filter",
      <AdvanceFilter
        payload={payload}
        setPayload={setPayload}
        setFilterResetFunc={setFilterResetFunc}
      />,
    );
  };

  return (
    <Card sx={{ width: "100%", maxWidth: mainContentWidth - 64 }}>
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
          justifyContent: "space-between",
          alignItems: "flex-start", // Adjust alignment as needed
        }}
        action={
          <Toolbar
            addPage={addPage}
            downloadFile={downloadFile}
            refreshPage={refreshPage}
            openFilterDialog={openFilterDialog}
            isLoadingDownload={isLoadingDownload}
            downloadFileType={downloadFileType}
            addSmsAndEmailPage={addTo}
          />
        }
        title={
          <Typography variant="h6" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
            {t("sms_to_franchise")}
          </Typography>
        }
        subheader={<Box sx={{ display: "flex", flexWrap: "wrap" }}>{breadCrump()}</Box>}
      />
      <CardContent sx={{ height: height - 180, display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={smsToTenants}
            getRowId={(row) => row["tenantId"]}
            rowCount={paging.totalNumberOfRecords}
            loading={false}
            rowsPerPageOptions={[5]}
            pagination
            page={paging.pageNumber}
            pageSize={paging.pageSize}
            paginationMode="server"
            onPageChange={(page) => onPageChange(page)}
            onPageSizeChange={(pageSize) => onPageChange(pageSize)}
            checkboxSelection
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              const selectedRowData = smsToTenants.filter((row) =>
                newRowSelectionModel.includes(row.tenantId),
              );

              setSelectedRows(selectedRowData);
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
            }
            sx={{
              "& .MuiDataGrid-row.even-row": {
                backgroundColor: "#f9f9f9", // Light gray for even rows
              },
              "& .MuiDataGrid-row.odd-row": {
                backgroundColor: "#ffffff", // White for odd rows
              },
            }}
          />
        </Box>
      </CardContent>{" "}
    </Card>
  );
}

export default CustomDataGrid;
