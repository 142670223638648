import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";

import useTenant from "../../../../hooks/useTenant";
import SearchableAutoComplete from "../../../../components/SearchableAutoComplete";
import { searchPayload } from "../../../../utils/common";
import CustomTextField from "../../../../components/CustomTextField";

const currentTenantIpTvPlanPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

function IpTvPlan(props) {
  const searchPayloadValue = searchPayload();
  const { tenantIpTvPlanAutoSearch, ipTvPlanAutoSearchResults, isIpTvTenantAutoSearchLoading } =
    useTenant();

  const { validationFields } = props;
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const [tenantIpTvPlanPayload, setTenantIpTvPlanPayload] = useState(currentTenantIpTvPlanPayload);
  const [tenantIpTvPlanOptions, setTenantIpTvPlanOptions] = useState([]);

  const tenant = watch("tenant");

  useEffect(() => {
    console.log(tenant, "dssssssssssssss");
    if (Object.keys(tenant).length != 0) {
      setTenantIpTvPlanPayload({
        ...tenantIpTvPlanPayload,
        filters: [
          {
            fieldName: "tenantId",
            fieldValue: tenant.value,
            op: "eq",
            dataType: "int",
            condition: "and",
            isJoin: true,
          },
        ],
      });
    } else {
      setTenantIpTvPlanOptions([]);
    }
  }, [tenant]);

  const fetchIpTvPlans = async () => {
    await tenantIpTvPlanAutoSearch(tenantIpTvPlanPayload);
  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchIpTvPlans();
    }
  }, [tenantIpTvPlanPayload]);

  useEffect(() => {
    console.log(ipTvPlanAutoSearchResults, "ipTvPlanAutoSearchResults");
    if (ipTvPlanAutoSearchResults.data.length !== 0) {
      const results = [...tenantIpTvPlanOptions, ...ipTvPlanAutoSearchResults.data];
      const filteredResults = results.filter(
        (value, index, self) => index === self.findIndex((t) => t.value === value.value),
      );
      filteredResults.sort((a, b) => a.label.localeCompare(b.label));
      setTenantIpTvPlanOptions(filteredResults);
    }
  }, [ipTvPlanAutoSearchResults]);

  return (
    <Grid container spacing={12} className="row" pt={8}>
      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <CustomTextField
          label={t("serial_no")}
          name="serialNo"
          control={control}
          fullWidth
          variant="outlined"
          errors={errors}
          fields={validationFields}
        />
      </Grid>
      <Grid item xs={8.5} sm={4.5} md={6} lg={6} xl={6}>
        <SearchableAutoComplete
          control={control}
          data={tenantIpTvPlanOptions}
          label={t("select_iptv")}
          payload={tenantIpTvPlanPayload}
          setPayload={setTenantIpTvPlanPayload}
          loading={isIpTvTenantAutoSearchLoading}
          name="ipTvPlan"
          multiple={false}
          defaultValue={""}
          hasNextPage={false}
          fields={validationFields}
        />
      </Grid>
    </Grid>
  );
}

export default IpTvPlan;
