import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  TextField as MuiTextField,
  Grid,
  CardHeader,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";

import SaveCancel from "../../../components/SaveCancel";
import useWallet from "../../../hooks/useWallet";
import TabPanel from "../../../components/TabPanel";
import { a11yProps } from "../../../utils/common";
import BasicDetail from "./components/BasicDetail";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/slices/snackbar";
import useAuth from "../../../hooks/useAuth";
import useTenant from "../../../hooks/useTenant";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const digitsOnly = (value) => {
  // Ensure the value is a valid number, not zero, and has a minimum absolute value of 1
  const numberValue = Number(value);
  return /^-?\d+(\.\d+)?$/.test(value) && Math.abs(numberValue) >= 1;
};

const validationSchema = Yup.object().shape({
  tenant: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required(),
  amount: Yup.string()
    .required("Amount is required")
    .test(
      "inputEntry",
      "The field should have digits only and the minimum absolute value should be 1",
      digitsOnly,
    ),
  comments: Yup.string().required("Required"),
  walletType: Yup.string().required("Required"),
});

function AddWallet() {
  const {
    getTenant,
    tenant,
    getPaymentGatewayKeyId,
    paymentGatewayKeyId,
    createTenantOrder,
    cancelTenantOrder,
    tenantSetting,
    getTenantSettings,
  } = useTenant();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const initialValues = {
    amount: "",
    walletType: "RECHARGE",
    comments: "",
    tenant: "",
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  console.log(user, "useruser111");

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const { t } = useTranslation();
  const { addWallet, hasError, errorMessage, isSubmitting, paymentTrans, addPaymentTrans } =
    useWallet();
  const [tabValue, setTabValue] = React.useState(0);

  const [isOnline, setIsOnline] = React.useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchTenant = async (tenantId) => {
    await getTenant(tenantId);
  };

  const fetchTenantSetting = async (tenantId) => {
    await getTenantSettings(tenantId);
  };

  const fetchPaymentGatewayId = async (tenantId) => {
    await getPaymentGatewayKeyId(tenantId);
  };

  const navigate = useNavigate();

  const breadCrump = () => {
    return (
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>C-Panal</Typography>
        <Typography>Wallet</Typography>
        <Typography>Add</Typography>
      </Breadcrumbs>
    );
  };

  useEffect(() => {
    if (user && user.businessType != "SUPER_ADMIN") {
      //setIsOnline(true);
      setValue("tenant", {
        label: user.name,
        value: user.tenantId,
      });
      fetchTenant(user.tenantId);
      fetchTenantSetting(user.tenantId);
    }
  }, [user]);

  useEffect(() => {
    if (paymentTrans) {
      console.log(paymentTrans, "paymentTranspaymentTrans");
      if (paymentTrans.actionUrl != "") {
        let form = document.createElement("form");
        var element1 = document.createElement("input");
        var element2 = document.createElement("input");

        form.method = "POST";
        form.action = paymentTrans.actionUrl;

        element1.type = "hidden";
        element1.value = paymentTrans.encRequest;
        element1.name = "encRequest";
        form.appendChild(element1);

        element2.type = "hidden";
        element2.value = paymentTrans.accessCode;
        element2.name = "access_code";
        form.appendChild(element2);

        document.body.appendChild(form);

        form.submit();
        //navigate(paymentTrans.actionUrl, { replace: true });
        //window.location = paymentTrans.actionUrl;
      }
    }
  }, [paymentTrans]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const order_status = params.get("order_status");
  console.log(order_status, "order_statusorder_status");

  useEffect(() => {
    if (order_status != null) {
      dispatch(showSuccessSnackbar("The transaction of the status is " + order_status));
    }
  }, [order_status]);

  useEffect(() => {
    if (tenant && Object.keys(tenant).length != 0) {
      fetchPaymentGatewayId(tenant?.mso?.tenantId);
    }
  }, [tenant]);

  const createTenOrder = async (data) => {
    try {
      const payload = {};
      let amount = data?.amount;

      if (amount == 0 || amount == undefined) {
        dispatch(showErrorSnackbar("Amount is not valid"));
      }
      payload["amount"] = amount;
      payload["tenantName"] = tenant?.tenantName;
      payload["mobileNo"] = tenant?.mobileNo;
      payload["currency"] = "INR";
      payload["tenantId"] = tenant?.tenantId;
      // Call the backend to create a Razorpay order
      const tenantOrderResponse = await createTenantOrder(payload);
      initiatePayment(payload, tenantOrderResponse);
    } catch (error) {
      console.error("Error creating order:", error);
      dispatch(showErrorSnackbar(error.message));
    }
  };

  const initiatePayment = async (payload, tenantOrderResponse) => {
    console.log(tenantOrderResponse, "tenantOrderId11111111111");
    const options = {
      key: paymentGatewayKeyId, // Replace with your Razorpay key id
      amount: payload.amount * 100, // Amount in paise (e.g., 50000 paise = 500 INR)
      currency: "INR",
      name: tenant?.mso?.tenantName,
      description: "Wallet payment added",
      order_id: tenantOrderResponse,
      handler: function (response) {
        // This function is triggered after successful payment
        dispatch(
          showSuccessSnackbar(`Payment successful! Payment ID: ${response.razorpay_payment_id}`),
        );
      },
      prefill: {
        name: payload?.tenantName, // Pre-fill user details
        email: payload?.emailId,
        contact: payload?.mobileNo,
      },
      notes: {
        address: `${tenant?.doorNo}, ${tenant?.streetName}, ${tenant?.villageName}, 
        ${tenant?.townName}, ${tenant?.talukName}, ${tenant?.districtName}, ${tenant?.stateName}, 
        ${tenant?.countryName} - ${tenant?.pinCode}`,
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: function () {
          console.log(tenantOrderResponse, "cancel order payment id");
          cancelTenantOrder({
            orderId: tenantOrderResponse,
            tenantId: tenant?.tenantId,
          });
          // This function is triggered when the user dismisses the payment window
          dispatch(showErrorSnackbar("Payment was cancelled by the user."));
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const onSubmit = async (data) => {
    if (user && user.businessType == "LOCAL_CABLE_OPERATOR" && !tenantSetting.allowOnlineDeposit) {
      dispatch(showErrorSnackbar("You are not elligible to payment!"));
    } else {
      try {
        if (tenantSetting.allowOnlineDeposit) {
          createTenOrder(data);
        } else {
          const tenantDto = {
            tenantId: data.tenant.value,
          };
          data["tenant"] = tenantDto;
          console.log(data, "datadata");
          await addWallet(data);
          dispatch(showSuccessSnackbar("Amount added successfully!"));
          reset();
        }
      } catch (err) {
        dispatch(showErrorSnackbar("Failed to add amount"));
        //setStatus({ sent: false });
        //setErrorSubmit({ submit: err.message });
      }
    }
  };

  const cancelBtn = () => {
    navigate("/wallet/list");
  };

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <Card mb={6}>
          <CardHeader title={t("add_wallet")} subheader={breadCrump()} />
          <CardContent>
            {/* {status && status.sent && (
                <Alert severity="success" my={3}>
                  Wallet added successfully!
                </Alert>
              )} */}

            {/* {errorSubmit && errorSubmit.submit && (
                <Alert severity="error" my={3}>
                  {errorSubmit.submit}
                </Alert>
              )} */}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="Wallet">
                    <Tab label="Details" {...a11yProps(0)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <BasicDetail validationFields={validationSchema.fields} />
                  </TabPanel>
                </Box>
                <SaveCancel cancelBtn={cancelBtn} resetForm={reset} />
              </form>
            )}
          </CardContent>
        </Card>
      </FormProvider>
    </React.Fragment>
  );
}

export default AddWallet;
