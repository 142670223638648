import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, MenuItem } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import useTenant from "../../../hooks/useTenant";
import SearchableAutoComplete from "../../../components/SearchableAutoComplete";
import FilterReset from "../../../components/FilterReset";
import { searchPayload, convertedValue, invoiceStatusOptions } from "../../../utils/common";
import { OperatorConstants } from "../../../constants/operator.constants";
import ReactHookFormSelect from "../../../components/ReactHookFormSelect";
import CustomTextField from "../../../components/CustomTextField";
import { useDialog } from "../../../contexts/DialogContext";
import usePlan from "../../../hooks/usePlan";
import CustomDatePicker from "../../../components/CustomDatePicker";

const currentPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const statusOptions = [
  {
    label: "Payment Pending",
    value: "PP",
  },
  {
    label: "Un Paid",
    value: "UP",
  },
  {
    label: "Paid",
    value: "PA",
  },
  {
    label: "Canelled",
    value: "CA",
  },
];

function AdvanceFilter(props) {
  const { unSetDialog } = useDialog();
  const initialValues = {
    userNameOperator: "eq",
    firstNameOperator: "eq",
    planNameOperator: "eq",
    lastNameOperator: "eq",
    tenantNameOperator: "eq",
    statusOperator: "eq",
    userName: "",
    firstName: "",
    tenantName: [],
    planName: [],
    lastName: "",
    status: [],
    invoiceDate: [],
    invoiceDateStr: "",
  };

  const { fields, payload, setPayload, setFilterResetFunc } = props;

  const { autoSearchTenant, tenantAutoSearchResults } = useTenant();
  const { autoSearchPlan, planAutoSearchResults, isLoadingAutoSearch } = usePlan();

  const [planPayload, setPlanPayload] = useState(currentPayload);
  const [planOptions, setPlanOptions] = useState([]);
  const [planMultiValue, setPlanMultiValue] = useState(false);
  const [tenantPayload, setTenantPayload] = useState(currentPayload);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [tenantMultiValue, setTenantMultiValue] = useState(false);
  const [statusMultiValue, setStatusMultiValue] = useState(false);
  const [defaultTenant, setDefaultTenant] = useState([]);

  const [defaultStatus, setDefaultStatus] = useState([]);
  const [defaultPlan, setDefaultPlan] = useState([]);

  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: initialValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    setFilterResetFunc(() => reset);
  }, [reset]);

  const tenantOpValue = watch("tenantNameOperator");
  const planOpValue = watch("planNameOperator");

  const statusOpValue = watch("statusOperator");

  const onSubmit = async (data) => {
    try {
      let filters = [];
      if (data.userName != null && Object.keys(data.userName).length != 0) {
        filters.push({
          fieldName: "userName",
          fieldValue: data.userName,
          op: data.userNameOperator,
          dataType: "string",
          condition: "and",
          isJoin: true,
          entityName: "RadUserEntity",
          attributeName: "radUser",
        });
      }
      if (data.firstName != null && Object.keys(data.firstName).length != 0) {
        filters.push({
          fieldName: "firstName",
          fieldValue: data.firstName,
          op: data.firstNameOperator,
          dataType: "string",
          condition: "and",
          isJoin: true,
          entityName: "RadUserEntity",
          attributeName: "radUser",
        });
      }
      if (data.lastName != null && Object.keys(data.lastName).length != 0) {
        filters.push({
          fieldName: "lastName",
          fieldValue: data.lastName,
          op: data.lastNameOperator,
          dataType: "string",
          condition: "and",
          isJoin: true,
          entityName: "RadUserEntity",
          attributeName: "radUser",
        });
      }
      if (data.planName != null && Object.keys(data.planName).length != 0) {
        filters.push({
          fieldName: "planId",
          fieldValue: convertedValue(data.planName, "value").join(","),
          op: data.planNameOperator,
          dataType: "int",
          condition: "and",
          isJoin: true,
          entityName: "PlanEntity",
          attributeName: "plan",
        });
      }
      if (data.tenantName != null && Object.keys(data.tenantName).length != 0) {
        filters.push({
          fieldName: "tenantId",
          fieldValue: convertedValue(data.tenantName, "value").join(","),
          op: data.tenantNameOperator,
          dataType: "int",
          condition: "and",
          isJoin: true,
          entityName: "TenantEntity",
          attributeName: "tenant",
        });
      }
      if (data.status != null && Object.keys(data.status).length != 0) {
        filters.push({
          fieldName: "invoiceStatus",
          fieldValue: convertedValue(data.status, "value").join(","),
          op: data.statusOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }
      if (data.invoiceDate != "" && data.invoiceDate != null) {
        filters.push({
          fieldName: "invoiceDate",
          fieldValue: data.invoiceDateStr,
          op: data.invoiceDateOperator,
          dataType: "string",
          condition: "and",
          isJoin: false,
        });
      }

      setPayload({
        ...payload,
        filters: filters,
      });
      unSetDialog("advance_filter");
    } catch (err) {
      console.error(err, "errerrerrerr");
    }
  };

  useEffect(() => {
    if (tenantOpValue == "in" || tenantOpValue == "nin") {
      setDefaultTenant([]);
      setValue("tenantName", []);
      setTenantMultiValue(true);
    } else {
      setDefaultTenant({});
      setValue("tenantName", {});
      setTenantMultiValue(false);
    }
  }, [tenantOpValue]);

  useEffect(() => {
    if (planOpValue == "in" || planOpValue == "nin") {
      setDefaultPlan([]);
      setValue("planName", []);
      setPlanMultiValue(true);
    } else {
      setDefaultPlan({});
      setValue("planName", {});
      setPlanMultiValue(false);
    }
  }, [planOpValue]);

  useEffect(() => {
    if (statusOpValue == "in" || statusOpValue == "nin") {
      setDefaultStatus([]);
      setValue("status", []);
      setStatusMultiValue(true);
    } else {
      setDefaultStatus({});
      setValue("status", {});
      setStatusMultiValue(false);
    }
  }, [statusOpValue]);

  const fetchTenant = async () => {
    await autoSearchTenant(tenantPayload);
  };
  useEffect(() => {
    fetchTenant();
  }, [tenantPayload]);

  useEffect(() => {
    if (tenantAutoSearchResults.data.length !== 0) {
      setTenantOptions([...tenantOptions, ...tenantAutoSearchResults.data]);
    }
  }, [tenantAutoSearchResults]);

  const fetchPlan = async () => {
    await autoSearchPlan(planPayload);
  };
  useEffect(() => {
    fetchPlan();
  }, [planPayload]);

  useEffect(() => {
    if (planAutoSearchResults.data.length !== 0) {
      setPlanOptions([...planOptions, ...planAutoSearchResults.data]);
    }
  }, [planAutoSearchResults]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="userNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.string).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("user_name")}
                name="userName"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="tenantNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <SearchableAutoComplete
                control={control}
                data={tenantOptions}
                label="Franchise Name"
                payload={tenantPayload}
                setPayload={setTenantPayload}
                loading={isLoadingAutoSearch}
                name="tenantName"
                multiple={tenantMultiValue}
                defaultValue={defaultTenant}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="firstNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("first_name")}
                name="firstName"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="lastNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomTextField
                label={t("last_name")}
                name="lastName"
                control={control}
                fullWidth
                variant="outlined"
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="planNameOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((value, k) => (
                  <MenuItem key={k} value={value[0]}>
                    {value[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <SearchableAutoComplete
                control={control}
                data={planOptions}
                label="Plan Name"
                payload={planPayload}
                setPayload={setPlanPayload}
                loading={isLoadingAutoSearch}
                name="planName"
                multiple={planMultiValue}
                defaultValue={defaultPlan}
              />
            </Grid>

            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="statusOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.dropdown).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <SearchableAutoComplete
                control={control}
                data={invoiceStatusOptions()}
                label="Status"
                payload={null}
                setPayload={null}
                loading={false}
                name="status"
                multiple={statusMultiValue}
                defaultValue={defaultStatus}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} pt={8}>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="invoiceDateOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.date).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomDatePicker
                name="invoiceDate"
                nameTemp="invoiceDateStr"
                label="Invoice Date From"
                range={true}
                control={control}
              />
            </Grid>
            <Grid item md={2} sm={6}>
              <ReactHookFormSelect
                name="invoiceDateOperator"
                label={t("Operator")}
                control={control}
                fullWidth
                variant="outlined"
                fields={fields}
                errors={errors}
              >
                {Object.entries(OperatorConstants.date).map((t, k) => (
                  <MenuItem key={k} value={t[0]}>
                    {t[1]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid item md={4} sm={6} xs={8}>
              <CustomDatePicker
                name="invoiceDate"
                nameTemp="invoiceDateStr"
                label="Invoice Date To"
                range={true}
                control={control}
              />
            </Grid>
          </Grid>

          <FilterReset resetForm={reset} />
        </form>
      </FormProvider>
    </React.Fragment>
  );
}

export default AdvanceFilter;
