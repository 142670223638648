import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import TenantReducer from "../reducers/TenantReducer";
import { isValidToken, setSession } from "../utils/jwt";
import { TenantConstants } from "../constants/index";
import { saveFile } from "../utils/common";
const initialState = {
  tenants: [],
  tenantIppools: [],
  paging: {
    totalNumberOfRecords: 0,
    totalNumberOfPages: 0,
    pageNumber: 0,
    pageSize: 0,
  },
  hasError: false,
  errorMessage: "",
  tenantSearchResults: [],
  tenant: {},
  showPass: {},
  isUpload: false,
  uploadError: false,
  uploadSuccess: false,
  isSubmitting: false,
  tenantPlans: [],
  tenantTenants: [],
  isLoading: true,
  isLoadingDownload: false,
  tenantAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  autoSearchLoading: false,
  isLoadingAutoSearch: true,
  downloadFileType: "",
  planAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  ipTvPlanAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  tenantIpTvPlans: [],
  isAutoSearchLoading: true,
  isTenantAutoSearchLoading: true,
  isIpTvTenantAutoSearchLoading: true,
  planGroupPlanAutoSearchResults: {
    data: [],
    paging: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageNumber: 0,
      pageSize: 0,
      totalNumberOfPages: 0,
      totalNumberOfRecords: 0,
    },
  },
  paymentGatewayKeyId: "",
  userOrderId: "",
  tenantSetting: {},
  tenantLogo: null,
  tenantInvoiceLogo: null,
};
const initPayload = {
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 100,
  },
  sortFields: [],
};

const TenantContext = createContext({
  ...initialState,
  getAllTenant: () => {},
  addTenant: () => {},
  searchTenant: () => {},
  autoSearchTenant: () => {},
  getTenant: () => {},
  getShowPass: () => {},
  updateTenant: () => {},
  downloadFile: () => {},
  sampleFile: () => {},
  uploadFile: () => {},
  uploadInvoiceLogo: () => {},
  uploadLogo: () => {},
  addTenantPlan: () => {},
  addTenantIppool: () => {},
  getTenantPlans: () => {},
  getTenantIppools: () => {},
  tenantPlanAutoSearch: () => {},
  tenantIpTvPlanAutoSearch: () => {},
  getTenantIpTvPlans: () => {},
  addTenantIpTvPlan: () => {},
  tenantPlanGroupPlanAutoSearch: () => {},
  getPaymentGatewayKeyId: () => {},
  createTenantOrder: () => {},
  cancelTenantOrder: () => {},
  getTenantSettings: () => {},
  getTenantLogo: () => {},
  getTenantInvoiceLogo: () => {},
});

function TenantProvider({ children }) {
  const [state, dispatch] = useReducer(TenantReducer, initialState);

  const getAllTenant = async (payload) => {
    try {
      dispatch({
        type: TenantConstants.FETCH_TENANT_REQUEST,
      });
      const response = await axios.post("/cpanel/tenant/list", payload);

      dispatch({
        type: TenantConstants.FETCH_TENANT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: TenantConstants.FETCH_TENANT_FAILURE,
      });
    }
  };
  const searchTenant = async () => {
    try {
      const response = await axios.post("/cpanel/tenant/search", initPayload);
      dispatch({
        type: TenantConstants.TENANT_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: TenantConstants.TENANT_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: TenantConstants.TENANT_SEARCH_FAILURE,
      });
    }
  };
  const addTenant = async (payload) => {
    const response = await axios.post("/cpanel/tenant/add", payload);
    dispatch({
      type: TenantConstants.ADD_TENANT_REQUEST,
    });
    dispatch({
      type: TenantConstants.ADD_TENANT_SUCCESS,
      payload: response.data,
    });
  };

  const autoSearchTenant = async (payload) => {
    try {
      const response = await axios.post("/cpanel/tenant/auto-search", payload);
      dispatch({
        type: TenantConstants.TENANT_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: TenantConstants.TENANT_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: TenantConstants.TENANT_AUTO_SEARCH_FAILURE,
        payload: exception,
      });
    }
  };

  const tenantPlanAutoSearch = async (payload) => {
    try {
      const response = await axios.post("/cpanel/tenant/plan/auto-search", payload);
      dispatch({
        type: TenantConstants.TENANT_PLAN_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: TenantConstants.TENANT_PLAN_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: TenantConstants.TENANT_PLAN_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const uploadInvoiceLogo = async (file) => {
    dispatch({
      type: TenantConstants.UPLOAD_INVOICE_LOGO_REQUEST,
    });
    const response = await axios({
      url: "/cpanel/tenant/upload-invoice-logo",
      method: "POST",
      data: file,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({
      type: TenantConstants.UPLOAD_INVOICE_LOGO_SUCCESS,
      payload: response.data,
    });
  };

  const uploadLogo = async (file) => {
    try {
      dispatch({
        type: TenantConstants.UPLOAD_LOGO_REQUEST,
      });
      const response = await axios({
        url: "/cpanel/tenant/settings/upload-logo",
        method: "POST",
        data: file,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: TenantConstants.UPLOAD_LOGO_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: TenantConstants.UPLOAD_LOGO_FAILURE,
      });
    }
  };

  const tenantPlanGroupPlanAutoSearch = async (tenantId, payload) => {
    try {
      const response = await axios.post(
        "/cpanel/tenant/plan-group-plan/auto-search/" + tenantId,
        payload,
      );
      dispatch({
        type: TenantConstants.TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data");
      dispatch({
        type: TenantConstants.TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: TenantConstants.TENANT_PLAN_GROUP_PLAN_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const tenantIpTvPlanAutoSearch = async (payload) => {
    try {
      const response = await axios.post("/cpanel/tenant/iptv-plan/auto-search", payload);
      dispatch({
        type: TenantConstants.TENANT_IPTV_PLAN_AUTO_SEARCH_REQUEST,
      });
      console.log(response.data, "response.data1111111");
      dispatch({
        type: TenantConstants.TENANT_IPTV_PLAN_AUTO_SEARCH_SUCCESS,
        payload: response.data,
      });
    } catch (exception) {
      dispatch({
        type: TenantConstants.TENANT_IPTV_PLAN_AUTO_SEARCH_FAILURE,
      });
    }
  };

  const getTenant = async (id) => {
    const response = await axios.get("/cpanel/tenant/" + id);
    dispatch({
      type: TenantConstants.GET_TENANT_REQUEST,
    });
    dispatch({
      type: TenantConstants.GET_TENANT_SUCCESS,
      payload: response.data,
    });
  };

  const getTenantSettings = async (tenantId) => {
    const response = await axios.get("/cpanel/tenant/settings/" + tenantId);
    dispatch({
      type: TenantConstants.GET_TENANT_SETTING_REQUEST,
    });
    dispatch({
      type: TenantConstants.GET_TENANT_SETTING_SUCCESS,
      payload: response.data,
    });
  };

  const getShowPass = async (tenantId) => {
    const response = await axios.get("/cpanel/tenant/show-password/" + tenantId);
    dispatch({
      type: TenantConstants.GET_SHOW_PASSWORD_REQUEST,
    });
    dispatch({
      type: TenantConstants.GET_SHOW_PASSWORD_SUCCESS,
      payload: response.data,
    });
  };

  const updateTenant = async (payload) => {
    const response = await axios.put("/cpanel/tenant/update/" + payload.tenantId, payload);
    dispatch({
      type: TenantConstants.UPDATE_TENANT_REQUEST,
    });
    dispatch({
      type: TenantConstants.UPDATE_TENANT_SUCCESS,
      payload: response.data,
    });
  };

  const downloadFile = async (fileType, payload) => {
    dispatch({
      type: TenantConstants.DOWNLOAD_TENANT_REQUEST,
      payload: {
        fileType: fileType,
      },
    });
    const response = await axios.post("/cpanel/tenant/export/" + fileType, payload, {
      responseType: "blob",
    });
    console.log(response.headers, "filename");
    //const filename = response.headers.get("content-disposition").split('"');

    await saveFile(response.data, "tenant." + fileType);
    dispatch({
      type: TenantConstants.DOWNLOAD_TENANT_SUCCESS,
      payload: response.data,
    });
  };

  const sampleFile = async (fileType) => {
    const response = await axios.get("/cpanel/tenant/sample/" + fileType, {
      responseType: "blob",
    });

    await saveFile(response.data, "sample_tenant." + fileType);
    dispatch({
      type: TenantConstants.DOWNLOAD_TENANT_REQUEST,
    });
    dispatch({
      type: TenantConstants.DOWNLOAD_TENANT_SUCCESS,
      payload: response.data,
    });
  };

  const uploadFile = async (payload) => {
    try {
      dispatch({
        type: TenantConstants.UPLOAD_TENANT_REQUEST,
      });
      const response = await axios({
        url: "/cpanel/tenant/upload",
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: TenantConstants.UPLOAD_TENANT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.log("errr", error);
      dispatch({
        type: TenantConstants.UPLOAD_TENANT_FAILURE,
      });
    }
  };

  const addTenantPlan = async (payload) => {
    const response = await axios.post("/cpanel/tenant/plan/add", payload);
    dispatch({
      type: TenantConstants.ADD_TENANT_PLAN_REQUEST,
    });
    dispatch({
      type: TenantConstants.ADD_TENANT_PLAN_SUCCESS,
      payload: response.data,
    });
  };

  const addTenantIpTvPlan = async (payload) => {
    const response = await axios.post("/cpanel/tenant/iptv-plan/add", payload);
    dispatch({
      type: TenantConstants.ADD_TENANT_IPTV_PLAN_REQUEST,
    });
    dispatch({
      type: TenantConstants.ADD_TENANT_IPTV_PLAN_SUCCESS,
      payload: response.data,
    });
  };

  const addTenantIppool = async (payload) => {
    const response = await axios.post("/cpanel/tenant/ippool/add", payload);
    dispatch({
      type: TenantConstants.ADD_TENANT_IPPOOL_REQUEST,
    });
    dispatch({
      type: TenantConstants.ADD_TENANT_IPPOOL_SUCCESS,
      payload: response.data,
    });
  };

  const getTenantPlans = async (payload) => {
    try {
      const response = await axios.post("/cpanel/tenant/plan/list", payload);
      dispatch({
        type: TenantConstants.FETCH_TENANT_PLAN_REQUEST,
      });
      dispatch({
        type: TenantConstants.FETCH_TENANT_PLAN_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: TenantConstants.FETCH_TENANT_PLAN_FAILURE,
      });
    }
  };

  const getTenantIpTvPlans = async (payload) => {
    try {
      const response = await axios.post("/cpanel/tenant/iptv-plan/list", payload);
      dispatch({
        type: TenantConstants.FETCH_TENANT_IPTV_PLAN_REQUEST,
      });
      dispatch({
        type: TenantConstants.FETCH_TENANT_IPTV_PLAN_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: TenantConstants.FETCH_TENANT_IPTV_PLAN_FAILURE,
      });
    }
  };

  const getTenantIppools = async (payload) => {
    try {
      const response = await axios.post("/cpanel/tenant/ippool/list", payload);
      dispatch({
        type: TenantConstants.FETCH_TENANT_IPPOOL_REQUEST,
      });
      dispatch({
        type: TenantConstants.FETCH_TENANT_IPPOOL_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } catch (exception) {
      dispatch({
        type: TenantConstants.FETCH_TENANT_IPPOOL_FAILURE,
      });
    }
  };

  const getPaymentGatewayKeyId = async (tenantId) => {
    const response = await axios.get("/cpanel/tenant/payment-gateway/key-id/" + tenantId);
    dispatch({
      type: TenantConstants.GET_PAYMENT_GATEWAY_ID_REQUEST,
    });
    dispatch({
      type: TenantConstants.GET_PAYMENT_GATEWAY_ID_SUCCESS,
      payload: response.data,
    });
  };
  const createTenantOrder = async (payload) => {
    const response = await axios.post("/payment/create-tenant-order", payload);
    dispatch({
      type: TenantConstants.CREATE_TENANT_ORDER_REQUEST,
    });
    dispatch({
      type: TenantConstants.CREATE_TENANT_ORDER_SUCCESS,
      payload: response.data,
    });
    return response.data;
  };

  const cancelTenantOrder = async (payload) => {
    const response = await axios.post("/payment/cancel-order-tenant", payload);
    dispatch({
      type: TenantConstants.CANCEL_TENANT_ORDER_REQUEST,
    });
    dispatch({
      type: TenantConstants.CANCEL_TENANT_ORDER_SUCCESS,
      payload: response.data,
    });
    return response.data;
  };

  const getTenantLogo = async (tenantId) => {
    const response = await axios.get(`/cpanel/tenant/app-logo/${tenantId}`, {
      responseType: "blob",
    });

    dispatch({
      type: TenantConstants.GET_TENANT_LOGO_REQUEST,
    });
    dispatch({
      type: TenantConstants.GET_TENANT_LOGO_SUCCESS,
      payload: response.data,
    });
  };
  const getTenantInvoiceLogo = async (tenantId) => {
    const response = await axios.get(`/cpanel/tenant/invoice-logo/${tenantId}`, {
      responseType: "blob",
    });
    dispatch({
      type: TenantConstants.GET_TENANT_INVOICE_LOGO_REQUEST,
    });
    dispatch({
      type: TenantConstants.GET_TENANT_INVOICE_LOGO_SUCCESS,
      payload: response.data,
    });
  };

  return (
    <TenantContext.Provider
      value={{
        ...state,
        getAllTenant,
        addTenant,
        searchTenant,
        getTenant,
        updateTenant,
        downloadFile,
        sampleFile,
        getShowPass,
        uploadFile,
        addTenantPlan,
        getTenantPlans,
        getTenantIppools,
        addTenantIppool,
        autoSearchTenant,
        tenantPlanAutoSearch,
        getTenantIpTvPlans,
        addTenantIpTvPlan,
        tenantIpTvPlanAutoSearch,
        tenantPlanGroupPlanAutoSearch,
        uploadInvoiceLogo,
        uploadLogo,
        getPaymentGatewayKeyId,
        createTenantOrder,
        getTenantSettings,
        cancelTenantOrder,
        getTenantLogo,
        getTenantInvoiceLogo,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
}

export { TenantContext, TenantProvider };
